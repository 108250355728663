/* istanbul ignore file */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import filesize from "filesize";

import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  useGridApiRef,
  gridFilteredSortedRowIdsSelector, // https://mui.com/x/react-data-grid/filtering/#selectors - Get the id of the rows accessible after the filting process, contains the collapsed children
  // GridLinkOperator,
} from "@mui/x-data-grid-pro";

import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
// import { base as ldbase } from "../../../utils/LDFFUtils";
import { darken, lighten, styled } from "@mui/material/styles";

import PagingUI from "../../TableContainer/Pagination/PagingUI";
import "./styles.css";

import classes from "../../../themes/theme.module.css";

import { myDownloads } from "../../../utils/HttpClientProvider";

import { DATAGRID_TOOL_PADDING, DATAGRID_TABLE_META } from "./DataGrid";
import {
  // RESTORE_STATUS_PENDING,
  RESTORE_STATUS_READY,
  // RESTORE_STATUS_NEW,
  // RESTORE_STATUS_EXPIRED,
  // RESTORE_STATUS_STAGING,
} from "../../TableContainer/RestoreRequest";

import {
  setLocalStorageMydataFilters,
  getLocalStorageMydataFilters,
} from "../../../utils/LocalStorageUtils";

import userTool from "../../../utils/UserUtils";

const getBackgroundColor = (color, theme, coefficient) => ({
  backgroundColor: darken(color, coefficient),
  ...theme.applyStyles("light", {
    backgroundColor: lighten(color, coefficient),
  }),
});

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  "& .super-app-theme--ready": {
    ...getBackgroundColor(classes.moss50, theme, 0),
    "&:hover": {
      ...getBackgroundColor(classes.moss100, theme, 0),
    },
    "&.Mui-selected": {
      ...getBackgroundColor(classes.moss50, theme, 0.5),
      "&:hover": {
        ...getBackgroundColor(classes.moss50, theme, 0.4),
      },
    },
  },
  "& .super-app-theme--pending": {
    ...getBackgroundColor(classes.lemon, theme, 0),
    "&:hover": {
      ...getBackgroundColor(classes.lemon100, theme, 0),
    },
    "&.Mui-selected": {
      ...getBackgroundColor(classes.lemon, theme, 0.5),
      "&:hover": {
        ...getBackgroundColor(classes.lemon, theme, 0.4),
      },
    },
  },
  "& .super-app-theme--staging": {
    ...getBackgroundColor(classes.lemon, theme, 0),
    "&:hover": {
      ...getBackgroundColor(classes.lemon100, theme, 0),
    },
    "&.Mui-selected": {
      ...getBackgroundColor(classes.lemon, theme, 0.5),
      "&:hover": {
        ...getBackgroundColor(classes.lemon, theme, 0.4),
      },
    },
  },
  "& .super-app-theme--expired": {
    ...getBackgroundColor(classes.grey100, theme, 0),
    "&:hover": {
      ...getBackgroundColor(classes.grey200, theme, 0.6),
    },
    "&.Mui-selected": {
      ...getBackgroundColor(classes.grey200, theme, 0.5),
      "&:hover": {
        ...getBackgroundColor(classes.grey200, theme, 0.4),
      },
    },
  },
  "& .super-app-theme--download": {
    ...getBackgroundColor("#F9F9F9", theme, 0),
    "&:hover": {
      ...getBackgroundColor("#F9F9F9", theme, 0.6),
    },
    "&.Mui-selected": {
      ...getBackgroundColor("#F9F9F9", theme, 0.5),
      "&:hover": {
        ...getBackgroundColor("#F9F9F9", theme, 0.4),
      },
    },
  },
}));

const PAGE_SIZE_ARRAY_BASE = [10, 20, 50, 100];
let PAGE_SIZE_ARRAY = [...PAGE_SIZE_ARRAY_BASE];
const PAGE_SZIE_DEFAULT = PAGE_SIZE_ARRAY[2];
const PAGE_DEFAULT = 1;

// topRect is the app's outer container, excluding the app footer
const MyRestoreRequestDataGrid = ({
  topRect,
  currentUser,
  honeycomb,
  setHTTPError,
}) => {
  const [selectedIds, setSelectedIds] = useState([]); // UI row selection state can be arbitrarily controll by this array

  const [dataArray, setDataArray] = useState(null);
  const [loading, setLoading] = useState(true);

  const [gridContainerRect, setGridContainterRect] = useState(null);
  const [filterModel, setFilterModel] = useState({});

  useEffect(() => {
    const promise = myDownloads(
      parseInt(userTool.userId(currentUser), 10),
      honeycomb
    );
    promise
      .then((resp) => {
        if (resp.error) {
          setHTTPError(resp.error, resp.status);
        } else {
          const rowdata = resp.downloads;
          // sort on id to put latest on top
          const data =
            rowdata.length > 1 ? rowdata.sort((a, b) => b.id - a.id) : [];
          if (data.length > 0) {
            setDataArray(data);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const contactId = `${userTool.userId(currentUser)}`;

  // after filtering
  const [activeRows, setActiveRows] = React.useState({
    length: 0,
    filters: 0,
    rows: [],
  });
  const apiRef = useGridApiRef();

  const sortEventHandler = (data) => {
    honeycomb.sendUiInteractionSpan("my-download-page-filter-applied", {
      filters: data,
    });
  };

  const gridColumns = [
    {
      description: "Request id",
      field: "id",
      headerName: "ID",
      renderCell: (params) => {
        return (
          <Link
            component={RouterLink}
            to={params.row.request_href}
            target="_blank"
          >
            {params.row.id}
          </Link>
        );
      },
      width: 90,
    },
    {
      description: "Requested date",
      field: "submission_date",
      headerName: "Date",
      width: 160,
    },
    {
      description: "Number of files",
      field: "file_count",
      headerName: "# Files",
      width: 90,
    },
    {
      description: "Total file size",
      field: "size",
      headerName: "Size",
      valueGetter: (params) => {
        return filesize(params.row.size, { round: 1 });
      },
      width: 90,
    },
    {
      description: "Request Status",
      field: "request_status",
      headerName: "Archive request status",
      valueGetter: (params) => {
        const { request_status: status, expiration_date } = params.row;
        return status === RESTORE_STATUS_READY
          ? `${status.toUpperCase()} (expires on ${expiration_date})`
          : status.toUpperCase();
      },
      width: 260,
      autoWidth: true,
    },
  ];

  const filterSecName = "downloads";
  const activeColumns = gridColumns;
  const filterItems =
    filterModel && filterModel[filterSecName]
      ? filterModel[filterSecName].items.filter((d) => d.value)
      : null;

  const handleFilterChange = (model) => {
    const fModal = { ...filterModel };
    fModal[filterSecName] = model;
    honeycomb.sendUiInteractionSpan("my-download-filter-changed", {
      filters: {
        section: filterSecName,
        model: JSON.stringify(model),
      },
    });
    setFilterModel(fModal);
    setLocalStorageMydataFilters(currentUser, "myrestores", fModal);
  };

  // My Data filter stored in localStorage; do we need same function for this page?
  useEffect(() => {
    const fdata = getLocalStorageMydataFilters(currentUser, "myrestores");
    if (fdata) {
      setFilterModel(fdata);
      honeycomb.sendUiInteractionSpan(
        "my-download-localstorage-filters-loaded",
        {
          filters: fdata,
        }
      );
    }
  }, []);

  const gridContainerBoxRef = useRef(null); // for table height to reach app footer
  const gridRef = useRef(null); // to get current datagrid component width

  const initPageConfig = {
    page: PAGE_DEFAULT,
    pageSize: PAGE_SZIE_DEFAULT,
    pageTotal: 1,
    active: false,
  };
  const [pageConfig, _setPageConfig] = useState(initPageConfig);

  const [gridBoxHeight, setGridBoxHeight] = useState(200);

  // table header, row, and toolbar heights
  const [tableMeta, setTableMeta] = useState(DATAGRID_TABLE_META);

  const _setTableMeta = (density) => {
    const { value, headerHeight, rowHeight } = density;

    if (value !== tableMeta.density) {
      const meta = {
        ...tableMeta,
        density: value,
        headerHeight,
        rowHeight: rowHeight + 1,
      };
      setTableMeta(meta);
    }
  };

  const setPageConfig = (page, pageSize, active = true) => {
    const pageTotal = Math.ceil(
      (activeRows.length > 0 ? activeRows.length : dataArray.length) / pageSize
    );
    if (
      page !== pageConfig.page ||
      pageSize !== pageConfig.pageSize ||
      pageTotal !== pageConfig.pageTotal ||
      active !== pageConfig.active
    ) {
      const pconf = { ...pageConfig, pageTotal, page, pageSize, active };
      _setPageConfig(pconf);
    }
  };

  useEffect(() => {
    if (dataArray?.length > 0) {
      const psizeMax =
        activeRows.length > 0 ? activeRows.length : dataArray.length;
      PAGE_SIZE_ARRAY = [...PAGE_SIZE_ARRAY_BASE];
      if (psizeMax > PAGE_SIZE_ARRAY[PAGE_SIZE_ARRAY.length - 1]) {
        PAGE_SIZE_ARRAY.push(psizeMax);
      }

      setPageConfig(PAGE_DEFAULT, pageConfig.pageSize);
    }
  }, [dataArray, activeRows]);

  useEffect(() => {
    // deselection rows that are not in the filtered dataset
    if (selectedIds?.length > 0 && filterItems?.length > 0) {
      const newSelectedIds = selectedIds.filter((d) =>
        activeRows.rows.includes(d)
      );
      if (newSelectedIds.length !== selectedIds.length) {
        apiRef.current.setRowSelectionModel(newSelectedIds);
      }
    }
  }, [activeRows]);

  const rowsAfterFiltering = () => {
    if (apiRef.current && Object.keys(apiRef.current).length > 0) {
      // data set after applying filter, = full dataset if no filter is applied
      const filtered = gridFilteredSortedRowIdsSelector(apiRef);
      if (filtered.length !== activeRows.length) {
        setActiveRows({
          ...activeRows,
          length: filtered.length,
          rows: filtered,
        });
      }
    }
  };

  useEffect(() => {
    rowsAfterFiltering();
    // ref : https://codesandbox.io/p/sandbox/zen-panka-ex5d6?file=%2Fsrc%2FDemo.tsx%3A21%2C12-26%2C11
    if (apiRef.current.subscribeEvent) {
      apiRef.current.subscribeEvent("sortModelChange", (params) => {
        sortEventHandler(params);
      });
    }
  }, [apiRef.current]);

  const resetHeight = () => {
    if (gridContainerBoxRef?.current && topRect) {
      const rect = gridContainerBoxRef.current.getBoundingClientRect();
      setGridBoxHeight(topRect.bottom - rect.top); // vertical distance from rect.top to app.footer.top
    }
  };

  useEffect(() => {
    if (gridRef.current) {
      const { width, height } = gridRef.current.getBoundingClientRect();
      if (!gridContainerRect || gridContainerRect.width !== width) {
        setGridContainterRect({
          width,
          height,
        });
      }
    }
  }, [gridRef.current, topRect]);

  useEffect(() => {
    resetHeight();
  }, [gridContainerBoxRef.current, topRect]);

  const infoLabelSeparator = (
    <Typography style={{ padding: "0px 12px" }}>|</Typography>
  );
  const infoLabelNum = (num) => (
    <Typography style={{ fontWeight: 600, paddingRight: 4 }}>
      {" "}
      {num}{" "}
    </Typography>
  );

  const toolbarRef = useRef(null);
  const csvDataColumns = gridColumns
    .filter((d) => d.field !== "accessUsers")
    .map((d) => d.field);

  const CustomToolbar = () => {
    const toolItemGap = 1;
    return (
      <GridToolbarContainer
        ref={toolbarRef}
        sx={{ padding: `${DATAGRID_TOOL_PADDING}px` }}
      >
        <GridToolbarColumnsButton
          className="data-grid-toolbar-icon"
          sx={{ marginRight: toolItemGap }}
        />
        <GridToolbarFilterButton
          className="data-grid-toolbar-icon"
          sx={{ marginRight: toolItemGap }}
        />
        <GridToolbarDensitySelector
          className="data-grid-toolbar-icon"
          sx={{ marginRight: toolItemGap }}
        />
        <GridToolbarExport
          className="data-grid-toolbar-icon"
          csvOptions={{
            fields: csvDataColumns,
            fileName: `${contactId}_myrestores`,
          }}
        />
        <div style={{ display: "flex", marginLeft: "auto" }}>
          {selectedIds?.length > 0 ? (
            <>
              {infoLabelNum(selectedIds.length)}
              <Typography>
                {" "}
                row
                {selectedIds?.length > 1 ? "s" : ""} selected
              </Typography>
              {infoLabelSeparator}
            </>
          ) : null}

          {filterItems?.length > 0 ? (
            <>
              {infoLabelNum(activeRows.length)}
              <Typography>
                {" "}
                row{activeRows.length > 1 ? "s" : ""} after filter{" "}
              </Typography>
              {infoLabelSeparator}
            </>
          ) : null}

          {dataArray?.length > 0 ? (
            <>
              {" "}
              {infoLabelNum(dataArray.length)}
              <Typography>
                {} total row{dataArray.length > 1 ? "s" : ""}
              </Typography>
            </>
          ) : null}

          <div style={{ marginLeft: 100 }}>
            <PagingUI
              pageSizeLabel="Rows per page"
              pageTotal={pageConfig.pageTotal}
              pageSizeChanged={(size) => {
                honeycomb.sendUiInteractionSpan(
                  "grant-access-page-size-changed-by-user"
                );
                setPageConfig(1, size);
              }}
              pageNumChanged={(page) => {
                honeycomb.sendUiInteractionSpan(
                  "grant-access-page-number-changed-by-user"
                );
                setPageConfig(page, pageConfig.pageSize);
              }}
              pageSizeArray={PAGE_SIZE_ARRAY}
              pageSizeValue={pageConfig.pageSize}
              pageNumberValue={pageConfig.page}
            />
          </div>
        </div>
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    if (toolbarRef.current) {
      const toolHeight = toolbarRef.current.clientHeight;
      if (toolHeight !== tableMeta.toolHeight) {
        const meta = {
          ...tableMeta,
          toolHeight,
        };
        setTableMeta(meta);
      }
    }
  }, [toolbarRef.current]);

  // console.log("[DG]", filters);
  const dataGridInitialState = {
    columns: {
      columnVisibilityModel: {
        accessList_csv: false,
      },
    },
  };

  return (
    <Box>
      <Box
        ref={gridContainerBoxRef}
        sx={{
          // height: 400, // Math.min(tableHeight, tabelHeightMax),
          width: "100%",
          marginTop: "24px",
        }}
      >
        <Box sx={{ display: "flex", height: gridBoxHeight, minHeight: 100 }}>
          {/* need a minHeight to avoid the  "useResizeContainer - The parent DOM element of the data grid has an empty height." error when window height is too small */}
          {loading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : !dataArray || dataArray.length === 0 ? (
            <Typography
              style={{ fontSize: 18, fontStyle: "italic", opacity: 0.5 }}
            >
              No project data{" "}
            </Typography>
          ) : (
            <>
              <StyledDataGrid
                apiRef={apiRef}
                ref={gridRef}
                rows={dataArray}
                columns={activeColumns}
                initialState={dataGridInitialState}
                filterModel={
                  filterModel && filterModel[filterSecName]
                    ? filterModel[filterSecName]
                    : { items: [] }
                }
                onFilterModelChange={handleFilterChange}
                components={{ Toolbar: CustomToolbar }}
                checkboxSelection={false}
                rowsPerPageOptions={PAGE_SIZE_ARRAY.filter(
                  (d) => typeof d !== "string"
                )}
                paginationModel={{
                  page: pageConfig.active ? pageConfig.page - 1 : 0,
                  pageSize: pageConfig.active ? pageConfig.pageSize : 100,
                }}
                onRowSelectionModelChange={(ids) => {
                  // console.log("[onSelectionModelChange]", ids?.length);
                  setSelectedIds(ids);
                }}
                selectionModel={selectedIds}
                disableColumnMenu // hide disable the kebab menu in column header
                onStateChange={(state) => {
                  _setTableMeta(state.density);
                  rowsAfterFiltering(); // need to refresh the possible filtered results here
                }}
                getRowHeight={() => 52}
                pagination={pageConfig.active}
                hideFooter
                getRowClassName={(params) =>
                  `super-app-theme--${params.row.request_status}`
                }
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MyRestoreRequestDataGrid;

MyRestoreRequestDataGrid.defaultProps = {
  topRect: null,
  currentUser: {},
  honeycomb: null,
  setHTTPError: () => {},
};

MyRestoreRequestDataGrid.propTypes = {
  topRect: PropTypes.shape(),
  currentUser: PropTypes.shape(),
  honeycomb: PropTypes.shape(),
  setHTTPError: PropTypes.func,
  // ldClientMaster: PropTypes.shape().isRequired,
};
