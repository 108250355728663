// Ref: https://mui.com/material-ui/react-drawer Persistent drawer
//      https://github.com/jon20111/drawer-inside-div (drawer in container)
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import {
  useSearchParams,
  MY_DATA_PORTAL_MENU_KEY,
} from "../../hooks/useSearchParams";

import { base as ldbase } from "../../utils/LDFFUtils";

export const DRAWER_WIDTH = 200;

export const MENU_MY_DATA = "My Data";
export const MENU_MY_PROJECTS = "My Projects";
export const MENU_MY_PROPPSALS = "My Proposals";
export const MENU_MY_REQUESTS = "My Requests";
export const MENU_MY_DOWNLOADS = "My Downloads";
export const MENU_MY_INFO = "My Info";

export const MENU_DEFAULT = MENU_MY_PROJECTS;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DrawerLeft = ({ open, setOpen, setMenuItem, menu, ldClient }) => {
  const theme = useTheme();
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const thisRef = useRef(null);
  const { mdp, searchParams } = useSearchParams();
  const history = useHistory();

  const [activeItem, setActiveItem] = useState("");

  const myrestores_ff = ldbase(ldClient, "MyDownloads");

  useEffect(() => {
    if (menu) {
      setActiveItem(menu);
    }
  }, [menu]);

  /* istanbul ignore next */
  const updateMenu = () => {
    const neatMenu = activeItem.split("/")[0].trim(); // remove possible submenu
    if (mdp && mdp !== neatMenu) {
      setActiveItem(mdp);
      setMenuItem(mdp);
    } else if (!mdp && activeItem !== MENU_DEFAULT) {
      setActiveItem(MENU_DEFAULT);
      setMenuItem(MENU_DEFAULT);
    }
  };

  useEffect(() => {
    updateMenu();
  }, [window.location.search]);

  /* istanbul ignore next */
  const handleItemClick = (label) => {
    let updateURL = false;

    // TODO: for now "My Data" menu selection redirects to "My Propects", but will have its own page later.
    if (label === MENU_DEFAULT || label === MENU_MY_DATA) {
      // clicked on My Proposal or My Data
      if (mdp) {
        // current menu is not the above 2
        searchParams.delete(MY_DATA_PORTAL_MENU_KEY);
        updateURL = true;
      } else if (activeItem !== MENU_DEFAULT) {
        // has submenu
        setMenuItem(MENU_DEFAULT);
      }
    } else if (mdp !== label) {
      searchParams.set(MY_DATA_PORTAL_MENU_KEY, label);
      updateURL = true;
    }

    if (updateURL) {
      history.push(`?${searchParams.toString()}`);
    }
  };

  const menuItems = [
    {
      label: MENU_MY_DATA,
      top: true,
    },
    {
      label: MENU_MY_PROJECTS,
    },
    {
      label: MENU_MY_PROPPSALS,
      hide: true,
    },
    {
      label: MENU_MY_REQUESTS,
      top: true,
      disabled: !myrestores_ff,
    },
    {
      label: MENU_MY_DOWNLOADS,
      top: true,
      disabled: true,
    },
    {
      label: MENU_MY_INFO,
      top: true,
      disabled: true,
    },
  ];

  return (
    <Drawer
      ref={thisRef}
      sx={{
        width: DRAWER_WIDTH,
        height: "100%",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
          position: "absolute",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
      style={{ position: "relative", marginRight: "auto" }}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon data-testid="drawer-menu-toggle" />
          ) : (
            <ChevronRightIcon data-testid="drawer-menu-toggle" />
          )}
        </IconButton>
      </DrawerHeader>

      <MenuList style={{ margin: "0px 10px" }}>
        {menuItems
          .filter((d) => !d.hide)
          .map((d, idx) => {
            const key = `menuItem_${idx}`;
            return (
              <MenuItem
                key={key}
                onClick={() => handleItemClick(d.label)}
                sx={{
                  backgroundColor:
                    d.label === activeItem.split("/")[0].trim()
                      ? "#DFF9FF"
                      : "#fff",
                }}
                disabled={d.disabled}
              >
                {d.top ? null : <ListItemIcon />}
                <ListItemText>{d.label}</ListItemText>
              </MenuItem>
            );
          })}
      </MenuList>
    </Drawer>
  );
};

export default DrawerLeft;

DrawerLeft.defaultProps = {
  menu: null,
  ldClient: null,
};

DrawerLeft.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setMenuItem: PropTypes.func.isRequired,
  menu: PropTypes.string,
  ldClient: PropTypes.shape(),
};
