import React from "react";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";

import JDPTooltip, { ICON_NONE } from "../Tooltip/Tooltip";
import classes from "./ShoppingCart.module.css";
import IconWithCounterBadge, {
  ICON_CART,
} from "../IconWithBadge/IconWithCounterBadge";

import { useSearchParams } from "../../../hooks/useSearchParams";

const ShoppingCart = ({ count, tip, badgeBackgroundColor, size, cartRef }) => {
  const { searchParams, cart: cartpage } = useSearchParams();
  const history = useHistory();
  const handleClick = () => {
    if (!cartpage) {
      searchParams.delete("expanded"); // do not keep expanded param
      searchParams.delete("restoreid"); // remove restoreid param
      searchParams.set("cart", true);
      history.push(`${window.location.pathname}?${searchParams.toString()}`);
    }
  };

  const cart = (
    <IconWithCounterBadge
      cartRef={cartRef}
      iconType={ICON_CART}
      onClick={handleClick}
      iconColor={count === 0 ? classes.grey300 : classes.lake500}
      count={count}
      badgeBackgroundColor={badgeBackgroundColor}
      size={size}
    />
  );

  return tip ? (
    <JDPTooltip title={tip} iconType={ICON_NONE}>
      <span>{cart}</span>
    </JDPTooltip>
  ) : (
    cart
  );
};

export default ShoppingCart;

ShoppingCart.defaultProps = {
  count: 0,
  tip: "Number of datasets in the Cart",
  badgeBackgroundColor: classes.red500,
  size: "medium",
  cartRef: null,
};
ShoppingCart.propTypes = {
  count: PropTypes.number,
  tip: PropTypes.string,
  badgeBackgroundColor: PropTypes.string,
  size: PropTypes.string,
  cartRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};
