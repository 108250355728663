// Ref: https://mui.com/material-ui/react-drawer Persistent drawer
//      https://github.com/jon20111/drawer-inside-div (drawer in container)
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import classes from "./MyDataPortal.module.css";
import userTool from "../../utils/UserUtils";
import { myProjects } from "../../utils/HttpClientProvider";
import DataGrid from "./DataGrid/DataGrid";
import MyRestoreRequestDataGrid from "./DataGrid/MyRestoreRequestDataGrid";
import {
  DRAWER_WIDTH,
  MENU_MY_DATA,
  MENU_MY_PROJECTS,
  MENU_MY_REQUESTS,
} from "./LeftDrawer";

const GAP_SIZE = 4; // between the left drawer and the page content

// can't move AppBar to inside the component - will lose animation effect
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  marginLeft: `-${DRAWER_WIDTH}px`,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH + GAP_SIZE}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const RightPanel = ({
  topRect,
  open,
  setOpen,
  menu,
  setMenuItem,
  currentUser,
  honeycomb,
  setHTTPError,
  ...restProps
}) => {
  const config = menu
    ? {
        label:
          menu === MENU_MY_REQUESTS ? "My File Restoration Requests" : menu,
        menu: menu.split("/")[0].trim(),
      }
    : {};

  const [rawData, setRawData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let isMounted = true;

    if (menu === MENU_MY_PROJECTS) {
      // to avoid the "Can't perform a React state update on an unmounted component" message in unit test
      const promise = myProjects(
        parseInt(userTool.userId(currentUser), 10),
        honeycomb
      );
      promise
        .then((resp) => {
          if (resp.error) {
            setHTTPError(resp.error, resp.status);
          } else {
            const data = resp.projects;
            if (data.length > 0) {
              setRawData({
                rows: data,
                pi_project_count: resp.pi_project_count,
                non_pi_project_count: resp.non_pi_project_count,
              });
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return () => {
      isMounted = false;
    };
  }, [menu]);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const getContent = (page) => {
    return (
      <Box
        style={{
          width: "100%",
          color: "#000",
          backgroundColor: "#fff",
          border: "1px solid #eee",
          // border: "1px solid #000",
          padding: 20,
        }}
      >
        {[MENU_MY_PROJECTS, MENU_MY_DATA].includes(page) ? (
          <DataGrid
            {...restProps}
            topRect={topRect}
            currentUser={currentUser}
            menu={menu}
            setSubmenu={(submenu) => {
              setMenuItem(`${config.menu} / ${submenu}`);
            }}
            manage={config.label.includes("Manage Access")}
            rawData={rawData?.rows}
            loading={loading}
            honeycomb={honeycomb}
          />
        ) : page === MENU_MY_REQUESTS ? (
          <MyRestoreRequestDataGrid
            {...restProps}
            topRect={topRect}
            currentUser={currentUser}
            honeycomb={honeycomb}
            setHTTPError={setHTTPError}
          />
        ) : (
          <Typography>
            Content for <span style={{ fontWeight: "bold" }}>{page}</span> is
            under construction. Please come back later.{" "}
          </Typography>
        )}
      </Box>
    );
  };

  const docURLMap = {};
  docURLMap[`${MENU_MY_PROJECTS}`] =
    "https://sites.google.com/lbl.gov/data-portal-help/home/tips_tutorials/my-data-portal";
  docURLMap[`${MENU_MY_REQUESTS}`] =
    "https://sites.google.com/lbl.gov/data-portal-help/home/tips_tutorials/my-data-portal#h.byvwxrevn0tr";

  return (
    <AppBar
      position="relative"
      open={open}
      sx={{
        height: 60,
        left: 0,
        backgroundColor: "#F2F2F2",
        border: "1px solid #979797",
      }}
      elevation={0} // remove shadow
    >
      <Toolbar style={{ display: "flex" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon sx={{ color: classes.lake500 }} />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          <span style={{ fontWeight: "bold" }}>{config.label}</span>
          {config.label === "My Projects" && rawData?.rows?.length > 0 && (
            <>
              <span style={{ paddingLeft: 22, fontWeight: "bold" }}>
                {rawData.rows.length}
              </span>
              <span> total projects (PI of </span>
              <span style={{ fontWeight: "bold" }}>
                {rawData.pi_project_count}
              </span>
              <span>)</span>
            </>
          )}
        </Typography>
        {menu in docURLMap && (
          <InfoOutlinedIcon
            sx={{ color: classes.lake500 }}
            style={{
              position: "relative",
              width: 32,
              height: 32,
              cursor: "pointer",
              top: -2,
              right: -10,
            }}
            onClick={() => {
              const win = window.open(docURLMap[menu], "_blank");
              win.focus();
            }}
          />
        )}
      </Toolbar>
      {getContent(config.menu)}
    </AppBar>
  );
};
export default RightPanel;

RightPanel.defaultProps = {
  topRect: null,
  honeycomb: null,
  setHTTPError: () => {},
};

RightPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  menu: PropTypes.string.isRequired,
  topRect: PropTypes.shape(),
  currentUser: PropTypes.shape().isRequired,
  setMenuItem: PropTypes.func.isRequired,
  honeycomb: PropTypes.shape(),
  setHTTPError: PropTypes.func,
};
