/* istanbul ignore file */
import { toInt } from "./StringUtils";
import userTool from "./UserUtils";

const LS_SELECTED_FILES = "SelectedFiles";
const LS_RELEVANT = "relevant";
const LS_NOTICES = "viewedNotices";
const LS_APPLY_FILTER = "forceFilter";
const LS_LOSE_SELECTION_OK = "loseSelectionOK";
const LS_IN_CART = "cartData";
const LS_SEARCH_COUNTS = "searchCounts";
const LS_MYDATA_FILTERS = "mydataFilters";
const LS_MINI_FILTER = "miniFilter"; // to save open | collapsed state

const ls_set = (kname, value) => {
  localStorage.setItem(kname, value);
  return true;
};

const ls_get = (kname, defVal) => {
  let val = localStorage.getItem(kname);
  // console.log(kname, defVal, val, "[ls_get]");

  if (val === null && defVal !== undefined) {
    return defVal;
  }

  // convert boolean string value to Bool
  switch (val) {
    case "true":
      val = true;
      break;
    case "false":
      val = false;
      break;
    default:
      break;
  }

  return val;
};

const ls_remove = (kname) => {
  localStorage.removeItem(kname);
  return true;
};

const setLocalStorageSelectedFiles = (flist) => {
  const key = window.location.href;
  const data = {
    key,
    data: flist,
  };
  return ls_set(LS_SELECTED_FILES, JSON.stringify(data));
};

const getLocalStorageSelectedFiles = (defVal = null) => {
  let val = ls_get(LS_SELECTED_FILES, defVal);
  if (val) {
    const key = window.location.href;
    const data = JSON.parse(val);
    val = data.key === key ? data.data : [];
  }
  return val;
};

const clearLocalStorageSelectedFiles = () => {
  ls_remove(LS_SELECTED_FILES);
};

const clearLocalStorage = (keep = []) => {
  const toKeep = [...keep];
  toKeep.push(LS_MYDATA_FILTERS);
  toKeep.push(LS_NOTICES);
  toKeep.push(LS_MINI_FILTER);
  const keys = Object.keys(localStorage);
  keys.forEach((kname) => {
    if (!toKeep.includes(kname)) {
      localStorage.removeItem(kname);
    }
  });
};

const setLocalStorageRelevant = (val) => {
  return ls_set(LS_RELEVANT, val);
};

const getLocalStorageRelevant = () => {
  return toInt(ls_get(LS_RELEVANT), 0);
};

const setLocalStorageViewedNotices = (val) => {
  return ls_set(LS_NOTICES, val);
};

const getLocalStorageViewedNotices = () => {
  const inLS = ls_get(LS_NOTICES);
  return inLS || [];
};

const setLocalStorageApplyFilter = (val) => {
  return ls_set(LS_APPLY_FILTER, val);
};

const getLocalStorageApplyFilter = (val) => {
  const lsVal = ls_get(LS_APPLY_FILTER);
  if (val === undefined) {
    return lsVal;
  }
  return lsVal === val;
};

const setLocalStorageLoseSelectionOK = (val) => {
  return ls_set(LS_LOSE_SELECTION_OK, val);
};

const getLocalStorageLoseSelectionOK = () => ls_get(LS_LOSE_SELECTION_OK);

const _saveJSONData = (keyname, val) => {
  if (!val || Object.keys(val).length === 0) {
    return { success: ls_remove(keyname) };
  }

  const valstr = typeof val === "object" ? JSON.stringify(val) : val;
  try {
    const ok = ls_set(keyname, valstr);
    return { success: ok };
  } catch (err) {
    return { error: err.message, byte_size: new Blob([valstr]).size };
  }
};
const setLocalStorageCartData = (val) => {
  return _saveJSONData(LS_IN_CART, val);
};

const clearLocalStorageCartData = () => {
  localStorage.removeItem(LS_IN_CART);
};

const getLocalStorageCartData = () => {
  return ls_get(LS_IN_CART);
};

const setLocalStorageSearchCounts = (val) => {
  return ls_set(LS_SEARCH_COUNTS, JSON.stringify(val));
};

const getLocalStorageSearchCounts = () => {
  const sData = ls_get(LS_SEARCH_COUNTS);
  if (sData) {
    return JSON.parse(sData);
  }
  return null;
};

const clearLocalStorageSearchCounts = () => {
  ls_remove(LS_SEARCH_COUNTS);
};

const mydataUserKey = (user) => {
  if (user) {
    const contactId = `${userTool.userId(user)}`;
    if (contactId) {
      return `u${contactId}`;
    }
  }
  return null;
};
const getLocalStorageMydataFilters = (user, tag) => {
  const data = ls_get(LS_MYDATA_FILTERS);

  try {
    const jdata = JSON.parse(data);
    const uid = mydataUserKey(user);
    if (jdata) {
      return uid && tag
        ? jdata[uid] && jdata[uid][tag]
        : uid
        ? jdata[uid]
        : jdata;
    }
  } catch {
    return null;
  }
};

const setLocalStorageMydataFilters = (user, tag, val) => {
  const uid = mydataUserKey(user);
  if (user && tag && val && uid) {
    const fdata = {};
    const cdata = getLocalStorageMydataFilters();
    if (cdata && cdata[uid]) {
      fdata[uid] = cdata[uid];
    } else {
      fdata[uid] = {};
    }

    fdata[uid][tag] = val;
    return _saveJSONData(LS_MYDATA_FILTERS, fdata);
  }
};

const setLocalStorageMiniFilter = (val) => {
  if (val) {
    ls_set(LS_MINI_FILTER, val);
  } else {
    ls_remove(LS_MINI_FILTER);
  }
};

const getLocalStoreMiniFilter = () => {
  return Boolean(ls_get(LS_MINI_FILTER));
};

export {
  LS_IN_CART,
  setLocalStorageSelectedFiles,
  getLocalStorageSelectedFiles,
  clearLocalStorageSelectedFiles,
  clearLocalStorage,
  setLocalStorageRelevant,
  getLocalStorageRelevant,
  setLocalStorageViewedNotices,
  getLocalStorageViewedNotices,
  getLocalStorageApplyFilter,
  setLocalStorageApplyFilter,
  setLocalStorageLoseSelectionOK,
  getLocalStorageLoseSelectionOK,
  setLocalStorageCartData,
  getLocalStorageCartData,
  clearLocalStorageCartData,
  setLocalStorageSearchCounts,
  getLocalStorageSearchCounts,
  clearLocalStorageSearchCounts,
  setLocalStorageMydataFilters,
  getLocalStorageMydataFilters,
  setLocalStorageMiniFilter,
  getLocalStoreMiniFilter,
};
