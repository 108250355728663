/* istanbul ignore file */
import React from "react";
import { PropTypes } from "prop-types";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useParams,
  // Redirect,
} from "react-router-dom";
import ImgBulkDownload from "../TableContainer/ImgBulkDownload";
import { useSearchParams } from "../../hooks/useSearchParams";

import {
  ERR_NOT_FOUND,
  ERR_NERSC_POWER_OUTAGE,
  ERR_SYSTEM_OUTAGE,
} from "../UI/ErrorMessage/ErrorMessage";

import appConfig from "../../config/appConfig";

import { useFileAndPageContext } from "../../context/FileAndPageContext";
import PageBrancher from "../TableContainer/PageBrancher";
import HomePageContainer from "../HomePage/HomePageContainer";
import MyDataPortal from "../MyDataPortal/MyDataPortal";

import {
  plannedNERSCOutageDowntime,
  genericOutage,
  // base as ldbase,
} from "../../utils/LDFFUtils";

const RoutingContainer = (props) => {
  const { ldClient, currentUser } = props;

  const { cart } = useSearchParams();

  // when at homepage with cart data, and cart in header is clicked, redirect /?cart=true to /search?cart=true to show the Cart page
  const pathname = window.location.pathname.trim();
  const toSearchWithCart = cart && ["/", "/mydata"].includes(pathname);

  const location = useLocation();
  const { searchParams } = useSearchParams();
  const isProteomePath = Boolean(searchParams.get("proteomeId"));

  const { handleLogin } = useFileAndPageContext();

  // handling redirect from old proteomeId query param to genome_id
  if (isProteomePath) {
    const fullUrl = `${location.pathname}${location.search}`;
    const redirectUrl = fullUrl.replace("proteomeId", "genome_id");
    return <Redirect to={redirectUrl} />;
  }

  if (ldClient) {
    const downtime = plannedNERSCOutageDowntime(ldClient);
    if (downtime && downtime !== "OFF") {
      throw new Error(ERR_NERSC_POWER_OUTAGE);
    }
    if (genericOutage(ldClient)) {
      throw new Error(ERR_SYSTEM_OUTAGE);
    }
  }

  // :segment is set first in src/index.js but this is the earliest place we can validate it
  // and throw a visible error.

  const { segment } = useParams();

  if (segment === "mydata" && currentUser.name === "Anonymous") {
    handleLogin();
    return;
  }

  const config = appConfig(ldClient);
  if (segment) {
    if (segment !== "mydata" && !config[segment]) {
      throw new Error(ERR_NOT_FOUND);
    }
  }

  // We do a 2nd round of routing here (the 1st round was in index.js)
  // because we need to preserve the :segment URL parameter
  // downstream components that call useParams() (for now -- TODO make that unnecessary)
  // Kingdom-specific and search routes both use TableContainer,
  // since "/search" is a match for :segment.

  return (
    <Switch>
      {toSearchWithCart ? <Redirect to="/search?cart=true" /> : null}
      <Route exact path="/">
        <HomePageContainer {...props} />
      </Route>

      <Route path="/mydata">
        <MyDataPortal {...props} />
      </Route>

      <Route path="/refine-download/img/bulk">
        <ImgBulkDownload {...props} />
      </Route>

      <Route path="/refine-download/:segment/">
        <PageBrancher {...props} />
      </Route>
      <Route path="/:segment">
        <PageBrancher {...props} />
      </Route>
      <Route
        render={() => {
          throw new Error(ERR_NOT_FOUND);
        }}
      />
    </Switch>
  );
};

export default RoutingContainer;

RoutingContainer.defaultProps = {
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  ldClient: null,
  currentUser: {},
};

RoutingContainer.propTypes = {
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  ldClient: PropTypes.shape({
    variation: PropTypes.func,
  }),
  currentUser: PropTypes.shape(),
};
