import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Box, Typography, AppBar, Paper, Table } from "@material-ui/core";

import Downloader from "../Downloader/Downloader";
import GenomeTable from "../GenomeTable/GenomeTable";
import SkeletonTable from "../GenomeTable/SkeletonTable";

import classes from "./TableContainer.module.css";
import { ShowOnScroll } from "./ScrollTransitions";
import { useApi } from "../../hooks/useApi";
import { useSearchParams } from "../../hooks/useSearchParams";
import { clearLocalStorageSelectedFiles } from "../../utils/LocalStorageUtils";

import Pagination from "./Pagination/Pagination";
import RestoreHaveUnreturnedFilesDlg, {
  NEED_LOGIN,
  NEED_AUTH,
  NO_DATA_NEED_LOGIN,
  NO_DATA_NEED_AUTH,
} from "./Dialogs/RestoreHaveUnreturnedFilesDlg";

import { PAGE_SIZE_ARRAY } from "./Pagination/PagingUI";

// restore status can be one of the following
export const RESTORE_STATUS_NEW = "new"; // maybe not a state, per Steven
export const RESTORE_STATUS_PENDING = "pending";
export const RESTORE_STATUS_STAGING = "staging";
export const RESTORE_STATUS_READY = "ready";
export const RESTORE_STATUS_EXPIRED = "expired";

const DEFAULT_PAGE_SIZE = PAGE_SIZE_ARRAY[0];

const RestoreRequest = (props) => {
  const { honeycomb, ldClient, currentUser } = props;
  const { useGenomeQuery, resetSearchCounts } = useApi(honeycomb);
  const { restoreid, include_private_data, psize } = useSearchParams();

  const [selection, setSelection] = useState(null);
  const [status, setStatus] = useState(null);

  const customParams = {};
  if (!psize) {
    customParams.x = DEFAULT_PAGE_SIZE;
  }

  const { data, isLoading, isFetchingNextPage } = useGenomeQuery(
    ldClient,
    customParams
  );
  const [dataSize, setDataSize] = useState(0);

  const [issue, setIssue] = useState(null);

  const fileOrIdListFromPageData = (pdata, idOnly = true) => {
    // helper to return full list of file or _id
    const alist = [];
    if (pdata?.organisms?.length > 0) {
      pdata.organisms.forEach((org) => {
        if (org.files?.length > 0) {
          org.files.forEach((file) => {
            if (idOnly) {
              alist.push(file._id);
            } else {
              alist.push(file);
            }
          });
        }
      });
    }
    return alist;
  };

  const checkDataIntegrity = (d) => {
    // eslint-disable-next-line no-unused-vars
    const { organisms, num_missing_files } = d;
    const withUser = currentUser.name !== "Anonymous";

    if (organisms.length > 0) {
      if (num_missing_files > 0) {
        if (withUser) {
          setIssue(NEED_AUTH); // current user does not the priviledge for some files
        } else {
          setIssue(NEED_LOGIN);
        }
      }
    } else if (num_missing_files > 0 && withUser) {
      setIssue(NO_DATA_NEED_AUTH); // current user does not the priviledge for some files
    } else if (num_missing_files > 0) {
      setIssue(NO_DATA_NEED_LOGIN);
    }
  };
  // if data from restore id, set all files as selected
  useEffect(() => {
    if (data) {
      setDataSize(data.total);
      const fileList = fileOrIdListFromPageData(data, false);
      setSelection(fileList); // make all files slected
      clearLocalStorageSelectedFiles();

      // for title line display
      if (data.status) {
        const title = {
          head: `Your download request ${restoreid}`,
          body: "is pending",
          tail: "",
          color: classes.orange700,
          tscolor: "#111111",
          expdate: `Expiration Date: ${
            data.expiration_date
              ? data.expiration_date.substring(0, 10)
              : "unknown date"
          }`,
        };

        switch (data.status) {
          case RESTORE_STATUS_READY:
            title.head = `Files for download ${restoreid}`;
            title.body = "are ready";
            title.color = classes.moss500;
            title.tail = "!";
            break;
          case RESTORE_STATUS_EXPIRED:
            title.head = `Your download request ${restoreid}`;
            title.body = "has expired";
            title.color = classes.red500;
            title.tscolor = classes.red500;
            break;
          default:
            break;
        }
        setStatus(title);
        checkDataIntegrity(data);
      }
    }
  }, [data]);

  const downloader = () => {
    let files = null;
    if (selection && data) {
      const { total_file_count, total_file_size } = data;
      files = {
        files: selection,
        total_file_count,
        total_file_size,
      };
      // JDP-2803
      if (include_private_data || data.include_private_data) {
        files.include_private_data = 1;
      }
    }

    return (
      <Downloader
        files={files}
        isLoading={isLoading}
        {...props}
        scoreMin={0}
        setSelectedFiles={setSelection}
        resetSearchCounts={resetSearchCounts}
        dataSize={dataSize}
        pageSize={psize ? parseInt(psize, 10) : DEFAULT_PAGE_SIZE}
      />
    );
  };

  const downloaderRef = useRef(null);

  // TODO : do we want to inform user of losing selections in page navigation??
  // const sizeChangeHandler = () => {
  //   return false; // abort change
  // };

  // const pageChangeHandler = () => {
  //   return false; // aboart change
  // };

  // console.log(`[Main] `, data);

  return (
    <>
      <Box className={classes.titleBox}>
        <Typography variant="h1">
          {status ? (
            <div>
              {status.head}{" "}
              <span
                style={{
                  color: status.color,
                }}
              >
                {status.body}
              </span>
              {status.tail}
            </div>
          ) : (
            `Files for download ${restoreid} ...`
          )}
          {status && (
            <span
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: status.tscolor,
                fontFamily: "Public Sans",
              }}
            >
              {status.expdate}
            </span>
          )}
        </Typography>
      </Box>
      <>
        {/* JDP-1786: Do not HideOnScroll for this set of combo. 
              Also DO NOT omit -- ref is used by ShowOnScroll set + change height to this combo causes height detection in ShowOnScroll flips outcome
           */}
        <Paper
          className={classes.downloaderBox}
          elevation={1}
          ref={downloaderRef}
        >
          {downloader()}
        </Paper>

        <div className={classes.searchInfoBox} style={{ marginBottom: 2 }}>
          <div style={{ marginLeft: "auto" }}>
            <Pagination dataSize={dataSize} />
          </div>
        </div>

        <ShowOnScroll {...props} refNode={downloaderRef}>
          <AppBar
            className={classes.appBar}
            style={{ margin: 0 }}
            elevation={1}
          >
            {downloader()}
          </AppBar>
        </ShowOnScroll>

        <Box className={classes.tableBox}>
          {isLoading || isFetchingNextPage ? (
            <Table
              size="medium"
              aria-label="loading more rows"
              stickyHeader
              className={classes.table}
            >
              <SkeletonTable rowCount={5} />
            </Table>
          ) : (
            <GenomeTable
              {...props}
              selectedFiles={selection}
              setSelectedFiles={setSelection}
              data={data}
              isLoading={isLoading}
              isFetchingNextPage={isFetchingNextPage}
              disableRelevancy
              restoreid={restoreid}
              pageSpec={{
                size: DEFAULT_PAGE_SIZE,
                page: 1,
                total: 1,
              }}
              showAll
            />
          )}
        </Box>
      </>
      {issue && (
        <RestoreHaveUnreturnedFilesDlg
          cancelBtnFn={() => {
            setIssue(null);
          }}
          reason={issue}
        />
      )}

      {/* TODO: remove if no dlg need to be shown when user changes page with de-selection  */}
      {/* {pendingPage !== null ? (
        <PageChangeWithSelectionDlg
          addToCartAndProceedBtnFn={() => {
            searchParams.set("p", pendingPage);
            setPendingPage(null);
            history.push(`?${searchParams.toString()}`);
          }}
          crossDismissBtnFn={() => {
            setPendingPage(null);
          }}
          dropFileAndProceedBtnFn={() => {
            setSelection(null);
            searchParams.set("p", pendingPage);
            setPendingPage(null);
            history.push(`?${searchParams.toString()}`);
            setPendingPage(null);
          }}
        />
      ) : null} */}
    </>
  );
};

export default RestoreRequest;

RestoreRequest.defaultProps = {
  restoreRecords: null,
  honeycomb: null,
  ldClient: null,
  currentUser: {},
  setAppMessageID: () => {},
};

RestoreRequest.propTypes = {
  restoreRecords: PropTypes.objectOf(Object),
  honeycomb: PropTypes.shape(),
  ldClient: PropTypes.shape(),
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    keycloak: PropTypes.bool,
  }),
  setAppMessageID: PropTypes.func,
};
