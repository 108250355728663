import React, { useState } from "react";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import { TableCell } from "@material-ui/core/";
import RestoreIcon from "@material-ui/icons/Restore";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VerifiedIcon from "@mui/icons-material/Verified";
import Grid from "@mui/material/Grid";
import filesize from "filesize";
import {
  fileStatusTransform,
  dateTransform,
  webReadable,
} from "../../../utils/FileUtils";
import getFileType from "../../../utils/SpeciesUtils";
import {
  STATUS_NOW,
  STATUS_ARCHIVED,
  STATUS_PENDING,
} from "../../../config/labelers";
import classes from "./FileTableCells.module.css";
import FileKebab from "../../KebabMenu/FileKebab";
import DUSChip from "../../UI/DUSChip/DUSChip";
import { DUS_META_VALUE_RST } from "../../KebabMenu/GenomeKebab";

import JDPTooltip from "../../UI/Tooltip/Tooltip";
import TransientTooltip from "../../UI/Tooltip/TransientTooltip/TransientTooltip";
import { JDP_NO_CL_API_URL } from "../../../utils/HttpClientProvider";

import { base as ldbase } from "../../../utils/LDFFUtils";
import * as cartTool from "../../../utils/CartDataTools";

// JDP-2166
import addToCartImage from "../../../assets/images/32x32_AddShoppingCartRounded.svg";
import removeFromCartImage from "../../../assets/images/32x32_RemoveShoppingCartRounded.svg";

import gtable_classes from "../../GenomeTable/GenomeTableCells/GenomeTableCells.module.css";

import { useSearchParams } from "../../../hooks/useSearchParams";

export const nowStatus = <span>{STATUS_NOW}</span>;

export const archivedStatus = (
  <span>
    <RestoreIcon
      color="action"
      fontSize="small"
      className={classes.statusIcon}
      data-testid="restoreSvg"
    />
    <span>{STATUS_ARCHIVED}</span>
  </span>
);

export const pendingStatus = (
  <span>
    <RestoreIcon
      color="action"
      fontSize="small"
      className={classes.statusIcon}
      data-testid="fileSvg"
    />
    <span>{STATUS_PENDING}</span>
  </span>
);

const getStatus = (row) => {
  let status;
  if (fileStatusTransform(row.file_status) === STATUS_NOW) {
    status = nowStatus;
  }
  if (fileStatusTransform(row.file_status) === STATUS_ARCHIVED) {
    status = (
      <Tooltip
        title="Once requested, this file will be ready for download within 24 hours."
        arrow
      >
        {archivedStatus}
      </Tooltip>
    );
  }
  if (fileStatusTransform(row.file_status) === STATUS_PENDING) {
    status = (
      <Tooltip
        title="This file is being retrieved from tape — check its status soon."
        arrow
      >
        {pendingStatus}
      </Tooltip>
    );
  }
  return status;
};

const FileTableCells = (props) => {
  const {
    row,
    headers,
    segment,
    selected,
    honeycomb,
    genomeId,
    ldClient,
    currentUser,
    cartData,
    restoreid,
    addFilesToCart,
    handleDeleteFromCart,
    selectedFiles,
    setSelectedFiles, // SearchPageContainer.setSelectedFiles
  } = props;
  const ldConsts = ldbase(ldClient, "open-files-within-browser");

  const cellFormatters = {
    file_type: (r) => getFileType(r, segment),
    file_size: (r) => filesize(r.file_size, { round: 1 }),
    file_date: (r) => dateTransform(r.file_date),
    file_status: (r) => getStatus(r),
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [cartUpdated, setCartUpdated] = useState(false);

  // for timed notice tooltip display
  /* istanbul ignore next */
  const doCartUpdated = () => {
    setCartUpdated(true);
    setTimeout(() => {
      setCartUpdated(false);
    }, 1500);
  };

  const {
    searchParams,
    include_private_data,
    cart: cartpage,
  } = useSearchParams();

  /* istanbul ignore next */
  const handleKebabClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    honeycomb.sendUiInteractionSpan("clicked-file-kebab-open", {
      genome_id: genomeId,
      file_id: row._id,
    });
  };

  const kebabTriggerIcon = (
    <div>
      <MoreVertIcon
        className={classes.kebab}
        onClick={handleKebabClick}
        data-testid="kebab-menu"
      />
      <FileKebab
        {...props}
        row={row}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        honeycomb={honeycomb}
      />
    </div>
  );

  const myprojects = searchParams.get("f") === "project_id";

  const jdpStatus = fileStatusTransform(row.file_status);
  const MAX_SIZE = 10 * 1024 * 1024; // 10MB

  /* istanbul ignore next */
  const maxSize = ldConsts?.web_file_max_size
    ? ldConsts.web_file_max_size
    : MAX_SIZE;

  /* istanbul ignore next */
  const canOpen =
    webReadable(row.file_name, ldConsts?.readable_extensions) &&
    ldConsts?.link_readable &&
    row.file_size <= maxSize &&
    jdpStatus === STATUS_NOW;

  const anonumous = currentUser.name === "Anonymous";

  // JDP-2737: file level mycocosm_portal_id is set when it exists at org level
  const fileHrefParams = [];
  if (row.mycocosm_portal_id) {
    fileHrefParams.push(`mycocosm_portal_id=${row.mycocosm_portal_id}`);
  }
  if (include_private_data || myprojects || row.include_private_data) {
    fileHrefParams.push("include_private_data=1");
  }
  const fileHref = `${JDP_NO_CL_API_URL}/filedownload/${row._id}/${
    row.file_name
  }${fileHrefParams.length > 0 ? `?${fileHrefParams.join("&")}` : ""}`;

  // DP-2282
  const tarFileRowStyle =
    segment === "img" && row.file_name.endsWith(".tar.gz")
      ? { fontWeight: "bold" }
      : {};

  const filenameWithTip =
    Object.keys(tarFileRowStyle).length > 0 ? (
      <JDPTooltip
        iconType={null}
        title="Preferred IMG download bundle.  Readme file(s) included."
        placement="bottom-start"
      >
        <div>{row.file_name}</div>
      </JDPTooltip>
    ) : (
      row.file_name
    );

  /* istanbul ignore next */
  const filename =
    !anonumous && canOpen ? (
      <Link
        href={fileHref}
        target="_blank"
        rel="noopener noreferrer"
        underline="always"
        onClick={() => {
          honeycomb.sendUiInteractionSpan(`file-opened-${row.file_name}`);
        }}
      >
        {filenameWithTip}
      </Link>
    ) : canOpen ? (
      <JDPTooltip title="Login to view the file content">
        <span
          style={{
            cursor: "pointer",
            color: "#1976d2",
            textDecoration: "underline",
          }}
        >
          {filenameWithTip}
        </span>
      </JDPTooltip>
    ) : (
      filenameWithTip
    );

  const { file_superseded_by } = row.metadata;

  /* istanbul ignore next */
  const textColor = file_superseded_by ? classes.grey600 : null;

  const rstFileNameUIChip =
    row.metadata.data_utilization_status === DUS_META_VALUE_RST ? (
      <DUSChip label="RST" />
    ) : null;

  /* istanbul ignore next */
  let fileNameUI = file_superseded_by ? (
    <JDPTooltip title="A newer version of this file exists.">
      <span style={{ ...tarFileRowStyle, color: textColor, cursor: "pointer" }}>
        {filename} {rstFileNameUIChip}
      </span>
    </JDPTooltip>
  ) : Object.keys(tarFileRowStyle).length > 0 ? (
    <span style={tarFileRowStyle}>
      {filename} {rstFileNameUIChip}
    </span>
  ) : (
    <>
      {filename}
      {rstFileNameUIChip}
    </>
  );

  if (Object.keys(tarFileRowStyle).length > 0) {
    fileNameUI = (
      <Grid container wrap="nowrap">
        <VerifiedIcon
          style={{
            color: classes.lake500,
            position: "relative",
            top: -2,
            marginRight: 4,
          }}
        />
        {fileNameUI}
      </Grid>
    );
  }

  // JDP-2166 the file row: shopping cart icon to the right end - do not show if restoreid exists or in cart page
  const doDelete = cartTool.fileInCart(cartData, row.organism_id, row._id);

  /* istanbul ignore next */
  const cartAddOrRemoveIcon =
    restoreid || cartpage ? null : (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <img
        className={
          doDelete
            ? gtable_classes.addToCartIcon24
            : gtable_classes.removeFromCartIcon24
        }
        src={doDelete ? removeFromCartImage : addToCartImage}
        alt="cart"
        onClick={() => {
          honeycomb.sendUiInteractionSpan("file-add-to-cart-click", {
            action: doDelete ? "delete" : "add",
          });
          if (doDelete) {
            handleDeleteFromCart(row);
            doCartUpdated();
          } else {
            // update selection to remove ALL selected files from this data set
            if (selectedFiles) {
              const newSelectedFiles = selectedFiles.filter(
                (f) => !(f.organism_id === row.organism_id && f._id === row._id)
              );
              setSelectedFiles(
                newSelectedFiles.length > 0 ? newSelectedFiles : null
              );
            }
            addFilesToCart([row]);
            doCartUpdated();
          }
        }} // Add or remove all ??
      />
    );

  const cartAddOrRemoveIconWithTip = cartAddOrRemoveIcon ? (
    <TransientTooltip
      noticeLabel={
        cartUpdated
          ? doDelete
            ? "File added to cart"
            : "File removed from cart"
          : null
      }
      labelConf={
        cartUpdated
          ? null
          : [
              {
                type: "title",
                value: doDelete ? "Remove from the cart" : "Add to the cart",
              },
            ]
      }
      child={cartAddOrRemoveIcon}
      open={cartUpdated}
      bottomOffset={0}
      // iconType={ICON_WARN}
      noticeBottomOffset={-10}
    />
  ) : null;

  return (
    <>
      <TableCell
        component="th"
        scope="row"
        padding="none"
        className={classes.dataCell}
        data-testid="File-name"
      >
        {fileNameUI}
        {selected && file_superseded_by ? <DUSChip label="OBSOLETE" /> : null}
      </TableCell>
      {headers
        .filter((header) => {
          return header.id !== "file_name";
        })
        .map((header) => {
          const formatter = cellFormatters[header.id];
          let cellValue = row[header.id];
          switch (header.id) {
            case "file_kebab":
              cellValue = kebabTriggerIcon;
              break;
            case "cart": // JDP-2166
              cellValue = cartAddOrRemoveIconWithTip;
              break;
            default:
              cellValue = formatter ? formatter(row) : row[header.id];
          }

          const testid =
            header.id !== "file_kebab"
              ? header.label.replace(/ /g, "-")
              : "file_kebab";
          const key = `${header.id}-${row._id}`;

          const cellstyle =
            header.id === "file_kebab"
              ? { marginLeft: 0, paddingLeft: 0, width: 10 }
              : header.label === "file_status"
              ? { maxWidth: 200 } // JDP-2166: narrower status column width - but not working
              : null;

          return (
            <TableCell
              key={key}
              align={header.align}
              data-testid={testid}
              style={cellstyle}
            >
              <div>
                <div
                  style={{
                    ...tarFileRowStyle,
                    display: "inline-block",
                    color: textColor,
                  }}
                >
                  {cellValue}
                </div>
              </div>
            </TableCell>
          );
        })}
    </>
  );
};

export default FileTableCells;

FileTableCells.defaultProps = {
  segment: "",
  headers: [],
  selected: false,
  row: {},
  // ldClient: null,
  genomeId: "",
  ldClient: null,
  currentUser: {},
  restoreid: null,
  cartData: null,
  addFilesToCart: null,
  selectedFiles: null,
  setSelectedFiles: () => {},
};

FileTableCells.propTypes = {
  segment: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.shape()),
  row: PropTypes.shape({
    _id: PropTypes.string,
    file_name: PropTypes.string,
    data_group: PropTypes.string,
    metadata: PropTypes.shape({
      file_superseded_by: PropTypes.string,
      phytozome: PropTypes.shape({
        phytozome_release_id: PropTypes.arrayOf(PropTypes.string),
      }),
      data_utilization_status: PropTypes.string,
    }),
    file_size: PropTypes.number,
    file_date: PropTypes.string,
    file_status: PropTypes.string,
    organism_id: PropTypes.string,
    include_private_data: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]),
    mycocosm_portal_id: PropTypes.string,
  }),
  ldClient: PropTypes.shape(),
  selected: PropTypes.bool,
  honeycomb: PropTypes.shape().isRequired,
  genomeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentUser: PropTypes.shape(),
  cartData: PropTypes.shape(),
  restoreid: PropTypes.string,
  addFilesToCart: PropTypes.func,
  handleDeleteFromCart: PropTypes.func.isRequired,
  selectedFiles: PropTypes.arrayOf(PropTypes.shape()),
  setSelectedFiles: PropTypes.func,
};
