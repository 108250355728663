/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import { isJGIValidYear } from "../../utils/TimeUtils";

/**
 * Date UI with date format of YYYY
 */
const YearUI = ({ style, valid, callback }) => {
  "valid = true or false : overwrite internal validate state";

  const [value, setValue] = React.useState("");

  const showErrorState = !isJGIValidYear(value);

  const _setValue = (val) => {
    setValue(val);
    callback(val);
  };

  return (
    <TextField
      error={valid === true ? false : valid === false ? true : showErrorState}
      variant="outlined"
      sx={style}
      placeholder="YYYY"
      fullWidth
      value={value}
      onChange={(event) => {
        const typed = event.target.value;
        if (!typed) {
          _setValue("");
        } else if (typed.length <= 4 && /^[0-9]+$/.test(typed)) {
          _setValue(typed);
        }
      }}
    />
  );
};

export default YearUI;

YearUI.defaultProps = {
  valid: null,
};
YearUI.propTypes = {
  style: PropTypes.shape().isRequired,
  callback: PropTypes.func.isRequired,
  valid: PropTypes.bool,
};
