/* istanbul ignore file */
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import lodash from "lodash";
import { useConfigContext } from "../context/ConfigContext";
import { useSearchParams } from "./useSearchParams";

import {
  setLocalStorageSearchCounts,
  getLocalStorageSearchCounts,
  clearLocalStorageSearchCounts,
} from "../utils/LocalStorageUtils";

export const URL_MAX_LEN = 3500; // nginx url max is 4K. leave some breathing room
export const encodeURIParamsString = (params) =>
  params
    ? new URLSearchParams(
        Object.fromEntries(
          Object.entries(params).map((e) => [
            e[0],
            typeof e[1] === "object" ? JSON.stringify(e[1]) : e[1],
          ])
        )
      ).toString()
    : "";

export const isUrlLengthOK = (params) => {
  return encodeURIParamsString(params).length <= URL_MAX_LEN;
};

/*
  expanded : search genome row open flag;
  cart : the Cart page flag
  cp : the page number in Cart page
  cx : the page size in Cart page
  restoreid: the FE value will be converted into part of the endpoint path, not used as param, /request_archived_files/requests/${restoreid}
  jdptest: for FE testing purpose
*/
const UI_PARAMS = ["expanded", "cart", "cp", "cx", "jdptest", "restoreid"]; // URL params only for UI purposes (non-query params)

export const useApiUtil = () => {
  const { fileFilters, organismFilters, goldFilters, requiredParams } =
    useConfigContext();

  const history = useHistory();
  const { searchParams, restoreid, FILE_NAME_REGX_KEY } = useSearchParams();

  // Build an object holding url params in the format expected by the BE
  // Puts organism and file filters in `of` and `ff` buckets
  const transformParams = (urlParams) => {
    const p = {};
    urlParams?.forEach((v, k) => {
      if (UI_PARAMS.includes(k)) {
        return;
      }

      if (k === FILE_NAME_REGX_KEY) {
        if (!p.ff) {
          p.ff = {};
        }
        p.ff.file_name_pattern = v;
      } else if (fileFilters && fileFilters.some((e) => e.filterId === k)) {
        if (!p.ff) {
          p.ff = {};
        }
        p.ff[k] = v
          .split(",")
          .map((val) => decodeURIComponent(val))
          .map((val) => val.split(";"))
          .flat()
          .map((val) => (val === "None" ? "NONE" : val));
      } else if (
        (organismFilters && organismFilters.some((e) => e.filterId === k)) ||
        (goldFilters && goldFilters.some((e) => e.filterId === k))
      ) {
        let valList = v
          .split(",")
          .map((val) => decodeURIComponent(val))
          .map((val) => val.split(";"))
          .flat()
          .map((val) =>
            ["None", "Current"].includes(val)
              ? "NONE"
              : val === "All" // JDP-1481, superseded ALL -> no filter
              ? ""
              : val
          );
        valList = valList.filter((d) => d !== "");
        if (valList.length > 0) {
          if (!p.of) {
            p.of = {};
          }
          p.of[k] = valList;
        }
      } else {
        p[k] = v;
      }
    });
    if (!p.q) {
      p.s = "name";
    }
    return p;
  };

  const [params, setParams] = useState(transformParams(searchParams));

  const [searchCounts, setSearchCounts] = useState(null); // global counter for URI changes (endpoint and search params)

  const resetSearchCounts = () => {
    clearLocalStorageSearchCounts();
    setSearchCounts();
  };

  const getEndpoint = (url) => url.split("?")[0].replace(/\//g, ""); // search, img_file_list, mycocosm_file_list, phytozome_file_list

  useEffect(() => {
    const sData = getLocalStorageSearchCounts();
    if (sData) {
      setSearchCounts(sData);
    }
  }, []);

  // when anything changes in the url (filters, etc) then alter "params" to trigger new XHR search
  const [location, setLocation] = useState(null);
  useEffect(() => {
    return history.listen((loc) => {
      setLocation(loc);
    });
  }, [history]);

  useEffect(() => {
    if (location?.search) {
      const { search, pathname } = location;

      if (search) {
        const urlParams = new URLSearchParams(search);
        const newParams = transformParams(urlParams);
        if (
          !lodash.isEqual(params, newParams) ||
          pathname !== searchCounts?.current
        ) {
          setParams(newParams);
        }
      } else if (pathname === "/refine-download/phytozome") {
        setParams({});
      }
    }
  }, [location?.search]);

  const hasRequiredParam = () => {
    if (!requiredParams) return false;

    for (let i = 0, len = requiredParams.length; i < len; i++) {
      const requiredParam = requiredParams[i];
      if (searchParams.has(requiredParam)) return true;
    }
    return false;
  };

  const addDataHelper = (klist, oriData, newData) => {
    klist.forEach((kname) => {
      if (kname in oriData) {
        newData[kname] = oriData[kname];
      }
    });
  };

  const RELEVANCE_THRESHOLD = 8; // JDP-1561, default if score_threshold key is missing from BE
  const scoreMin = (data) => {
    const sParams = new URL(document.location).searchParams;
    const score = sParams.get("score");
    return score || (data ? data.score_threshold : RELEVANCE_THRESHOLD);
  };

  const preProcessData = (data) => {
    // keep only the following top level data
    const topLevelKeys = [
      "facets",
      "total",
      "score_threshold",
      "prioritized_total",
      "status",
      "expiration_date",
      "file_total", // restore request - total restored files ( may have duplicates )
      // "unique_file_total", // restore request - total unique files restored
      "total_file_count", // total_file_count - same as unique_file_total
      "total_file_size", // restore request - total restored file size
      "num_missing_files", // restore request - # of private files not visible to the current user
      "include_private_data", // JDP-2803
    ];

    // keep only the following organism level data
    const orgLevelKeys = [
      "id",
      "agg_id",
      "score",
      "data_utilization_status",
      "prioritized_total",
      "expiration_date",
      "fileSize",
      "name",
      "label",
      "kingdom",
      "title",
      "dus_status",
      "superseded_by",
      "successor_name",
      "product_search_category",
      "portal_detail_id",
      "work_completion_date",
      "proposal_lay_description",
    ];

    // keep only the following file level data + file.metadata of fileMetadataKeys
    const fileLevelKeys = [
      "_id",
      "file_name",
      "file_path",
      "file_size",
      "file_type",
      "file_status",
      "file_date",
      "added_date",
      "dt_to_purge",
      "data_group",
    ];

    // keep only the following file.metadata level data + fileMetadataLevel2Map
    const fileMetadataKeys = [
      "type",
      "subtype",
      "content",
      "source",
      "collection",
      "data_utilization_status",
    ];

    // at deeper file.metadata, keep only the list for the following fields
    const fileMetadataLevel2Map = {
      proposal: ["date_approved", "award_doi"],
      phytozome: ["assembly_version", "phytozome_release_id"],
      portal: ["display_location"],
    };

    const cleanData = { organisms: [], relevant_min_score: scoreMin(data) };
    addDataHelper(topLevelKeys, data, cleanData);

    data.organisms.forEach((org) => {
      const { mycocosm_portal_id } = org; // only check organism level (although it can be in org.top_hit.metadata)

      const cleanOrg = {
        pi: {
          name: org.pi?.name,
          email_address: org.pi?.email_address,
        },
        eco_data: {
          Ecosystem: null,
          "Ecosystem category": null,
          "Ecosystem type": null,
          "Ecosystem subtype": null,
          "Specific ecosystem": null,
        },
        gold_proj_data: {},
        files: [],
      };
      addDataHelper(orgLevelKeys, org, cleanOrg);

      // add the organism.score.avg to file.score
      org.files.forEach((file) => {
        const cleanFile = {
          organism_id: org.id,
          score: org.score.avg,
          metadata: {},
          top_hit_id: org.top_hit?._id,
        };
        // label file with the org level mycocosm_portal_id
        if (mycocosm_portal_id) {
          cleanFile.mycocosm_portal_id = mycocosm_portal_id;
        }
        if (data.include_private_data) {
          // JDP-2737: restore request returns include_private_data if the request contains private data,
          // open file in browser will need it for private data
          cleanFile.include_private_data = 1;
        }

        Object.keys(fileMetadataLevel2Map).forEach((kname) => {
          if (kname in file.metadata) {
            if (!(kname in cleanFile.metadata)) {
              cleanFile.metadata[kname] = {};
            }

            addDataHelper(
              fileMetadataLevel2Map[kname],
              file.metadata[kname],
              cleanFile.metadata[kname]
            );
          }
        });
        addDataHelper(fileLevelKeys, file, cleanFile);
        addDataHelper(fileMetadataKeys, file.metadata, cleanFile.metadata);

        Object.keys(cleanOrg.eco_data).forEach((eco) => {
          const kname = eco.toLowerCase().replace(" ", "_");
          if (
            cleanOrg.eco_data[eco] === null &&
            file.metadata.gold_data &&
            file.metadata.gold_data[kname] &&
            !cleanOrg.eco_data[eco] // keep the first data found in files
          ) {
            cleanOrg.eco_data[eco] = file.metadata.gold_data[kname];
          }
        });

        // glod project urls:
        const url = file.metadata.gold_data?.gold_project_url;
        if (url) {
          const toks = url.split("?id=");
          if (toks.length === 2) {
            // there are bad URL's in JAMO; eg 8205.7.94233.ACTTGA.qtrim.3ptrim.artifact.rRNA.clean.v.2522572099.sorted.bai
            cleanOrg.gold_proj_data[
              toks[1]
            ] = `https://gold.jgi.doe.gov/resolver?id=${toks[1]}`; // JDP-2162, use resolver service to link GOLD page
          }
        }

        // JDP-2702: use organism level mycocosm_portal_id in file.metadata
        if (org.mycocosm_portal_id) {
          cleanFile.metadata.mycocosm_portal_id = org.mycocosm_portal_id;
        }

        cleanOrg.files.push(cleanFile);
      });

      cleanData.organisms.push(cleanOrg);
    });

    return cleanData;
  };

  const extendedURL = (url, cParmas) => {
    // build new url with the optional custom search params (cParams, key/val dict)
    if (!cParmas || Object.keys(cParmas).length === 0) return url;

    const hasParam = url.indexOf("?") > -1;
    const extP = Object.keys(cParmas)
      .map((k) => `${k}=${cParmas[k]}`)
      .join("&");
    return hasParam ? `${url}&${extP}` : `${url}?${extP}`;
  };

  const searchRec = () => {
    const { pathname } = window.location;
    const sData = getLocalStorageSearchCounts();
    const newSData = sData ? { ...sData } : {};

    newSData.current = pathname;

    if (!newSData[pathname]) {
      newSData[pathname] = 1;
    } else {
      newSData[pathname] += 1;
    }

    setLocalStorageSearchCounts(newSData);
    setSearchCounts(newSData);
  };

  return {
    restoreid,
    params,
    searchCounts,
    resetSearchCounts,
    preProcessData,
    searchRec,
    hasRequiredParam,
    getEndpoint,
    extendedURL,
  };
};

export { useApiUtil as default };
