import React from "react";
import PropTypes from "prop-types";
import Dialog, {
  TT_RED500,
} from "../../UI/Dialogs/DraggableDialog/DraggableDialog";
import { DF_BTN_ERROR } from "../../UI/Dialogs/DialogFooter";
import ModalContentBase from "../../UI/InfoBoard/ModalContentBase";

const ErrDlg = (props) => {
  const { handleClose } = props;

  const text =
    "We’re sorry — your download failed. Please check your connection, return to the page where you initiated your download, and try again.";
  return (
    <Dialog
      btnColor={DF_BTN_ERROR}
      topType={TT_RED500}
      acceptBtnFn={handleClose}
    >
      <ModalContentBase
        closeFn={handleClose}
        titleText="Browser download error"
      >
        {text}
      </ModalContentBase>
    </Dialog>
  );
};

export default ErrDlg;

ErrDlg.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
