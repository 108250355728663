/* istanbul ignore file */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";

import LeftDrawer, { MENU_DEFAULT } from "./LeftDrawer";
import RightPanel from "./RightPanel";
import HttpErrorDlg from "./DataGrid/Dialogs/HttpErrorDlg";

import classes from "./MyDataPortal.module.css";

const DLG_HTTP_ERROR = "DLG_HTTP_ERROR";
const MyDataPortal = ({
  topRect,
  currentUser,
  honeycomb,
  ldClient,
  ...restProps
}) => {
  const [open, setOpen] = useState(true);

  const [menuItem, setMenuItem] = useState(MENU_DEFAULT);

  const [dialogType, setDialogType] = useState(null);

  const setHTTPError = (message, status) => {
    setDialogType({
      type: DLG_HTTP_ERROR,
      message,
      status,
    });
  };

  const thisRef = useRef(null);
  const [thisHeight, setThisHeight] = useState(200); // default to non-null value

  const updateContentHeight = () => {
    if (thisRef?.current && topRect) {
      const rect = thisRef.current.getBoundingClientRect();
      setThisHeight(topRect.bottom - rect.top);
    }
  };
  useEffect(() => {
    updateContentHeight();
  }, [thisRef.current, topRect]);

  const showDialog = () => {
    switch (dialogType?.type) {
      case DLG_HTTP_ERROR:
        return (
          <HttpErrorDlg
            message={dialogType.message}
            status={dialogType.status}
            cancelBtnFn={() => {
              setDialogType(null);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box style={{ margin: "0px 120px", minWidth: "800px" }}>
      <Box className={classes.titleBox}>
        <div className={classes.title}>My Data Portal</div>
      </Box>
      <Box
        ref={thisRef}
        style={{
          display: "flex",
          position: "relative",
          // minHeight: 200,
          height: thisHeight,
        }}
      >
        <LeftDrawer
          open={open}
          setOpen={setOpen}
          setMenuItem={setMenuItem}
          menu={menuItem}
          ldClient={ldClient}
        />
        <RightPanel
          {...restProps}
          open={open}
          setOpen={setOpen}
          menu={menuItem}
          setMenuItem={setMenuItem}
          topRect={topRect}
          currentUser={currentUser}
          honeycomb={honeycomb}
          setHTTPError={setHTTPError}
        />
      </Box>
      {showDialog()}
    </Box>
  );
};

export default MyDataPortal;

MyDataPortal.defaultProps = {
  topRect: null,
  honeycomb: null,
  ldClient: null,
};

MyDataPortal.propTypes = {
  topRect: PropTypes.shape(),
  currentUser: PropTypes.shape().isRequired,
  honeycomb: PropTypes.shape(),
  ldClient: PropTypes.shape(),
};
