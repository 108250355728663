// JDP app constants

export const HREF_DOC_SWAGGER = "//files.jgi.doe.gov/apidoc/";
export const HREF_DOC_DOWNLOAD =
  "//sites.google.com/lbl.gov/data-portal-help/home/faq?authuser=0#h.xiq4wep4ednl";
export const HREF_DOC_ROOT =
  "//sites.google.com/lbl.gov/data-portal-help/home/faq";
export const HREF_DOC_HOW_TO_SEARCH =
  "//sites.google.com/lbl.gov/data-portal-help/home/faq#h.lvcsytvxa4sg";

export const HREF_DOC_HOW_TO_FILTER =
  "//sites.google.com/lbl.gov/data-portal-help/home/faq#h.3u2ppku5hy01";
export const HREF_DOC_RESTRICTED =
  "//sites.google.com/lbl.gov/data-portal-help/home/faq#h.wfjibfml8dfa";
export const HREF_DOC_ARCHIVED =
  "//sites.google.com/lbl.gov/data-portal-help/home/faq#h.3zyin7r02sgi";
export const HREF_DOC_DOWNLOAD_DAILY_LIMITS =
  "//sites.google.com/lbl.gov/data-portal-help/home/faq#h.49ki7m9ru1y4";
export const HREF_DOC_CURL_DOWNLOAD =
  "//sites.google.com/lbl.gov/data-portal-help/home/faq#h.89vwmt7wwn6p";

export const HREF_DOC_API =
  "//sites.google.com/lbl.gov/data-portal-help/home/faq#h.gh2djcefoy6q";
export const HREF_DOC_JGI_DATA =
  "//sites.google.com/lbl.gov/data-portal-help/home/faq#h.cqz559wu3mp0";
export const HREF_DOC_GLOBUS_AND_HOW =
  "//sites.google.com/lbl.gov/data-portal-help/home/faq#h.vugvmzd1v9qv";
export const HREF_DOC_CERTAIN_RESULTS =
  "//sites.google.com/lbl.gov/data-portal-help/home/faq#h.pu0hznvk1524";
export const HREF_DOC_ADVANCED_SEARCH =
  "//sites.google.com/lbl.gov/data-portal-help/home/tips_tutorials/search-tips#h.2gn2lox6kaam";

export const HREF_DOC_HELP_HOME =
  "//sites.google.com/lbl.gov/data-portal-help/home";
export const HREF_DOC_HELP_ANNO =
  "//sites.google.com/lbl.gov/data-portal-help/home/announcements";

const toFullURL = (url) => `https:${url}`;
export default toFullURL;
