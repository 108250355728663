import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { useKeycloak } from "@react-keycloak/web";

import { JDP_API_WHOAMI, whoami } from "../../../utils/HttpClientProvider";
import cookie from "../../../utils/CookieHandler";

const ssoURL = "https://signon.jgi.doe.gov/signon";
const domain = ".jgi.doe.gov"; // this requires our server on this domain

const anonymous = {
  name: "Anonymous",
  key: "Anonymous",
  anonymous: true,
};

const withUser = (WrappedComponent) => {
  const UserHOC = (props) => {
    // console.log("[hoc-with-user.UaweHOC] enter");
    const { honeycomb } = props;
    const [currentUser, setCurrentUser] = useState(anonymous);
    const [initializingUser, setInitializingUser] = useState(true);

    const { keycloak } = useKeycloak();

    // console.log(keycloak, "hoc-with-user");
    /** Update the current user in state and in honeycomb */
    const updateUser = (newUser) => {
      let doUpdate = false;
      if (!newUser) {
        doUpdate = true;
      } else {
        Object.keys(newUser).forEach((kname) => {
          if (newUser[kname] !== currentUser[kname]) {
            doUpdate = true;
          }
        });
      }

      if (doUpdate) {
        let updatedUser = { ...currentUser };
        if (newUser) {
          updatedUser = {
            name: newUser.email_address,
            key: newUser.email_address,
            ...newUser,
          };
        }

        setCurrentUser(updatedUser);

        // Also set the new user in honeycomb
        if (honeycomb && honeycomb.setHcUser) honeycomb.setHcUser(updatedUser);
      }
    };

    const handleLogout = () => {
      if (keycloak?.authenticated) {
        cookie.remove(cookie.KC_COOKIE_KEY);
        keycloak.logout();
      } else {
        cookie.setSSOReturnURL(); // so SSO server redirects back to this site after SSO sign off
        window.location.href = `${ssoURL}/destroy`;
      }

      updateUser(null);
    };

    const handleKCLogin = () => {
      keycloak.login();
    };

    const handleLogin = () => {
      // JGI SSO server
      const url = escape(window.location.href);
      document.cookie = `jgi_return=${url}; domain=${domain}; path=/;`;

      window.location.href = ssoURL;
    };

    /** Initilize user */
    useEffect(() => {
      let cancelled = false;

      if (process.env.REACT_APP_FAKE_USER) {
        updateUser({
          email_address: process.env.REACT_APP_FAKE_USER,
          anonymous: false,
          id: 0,
          first_name: "No",
          last_name: "Body",
          login: process.env.REACT_APP_FAKE_USER,
        });
        setInitializingUser(false);
        return;
      }

      const trace = honeycomb.buildTrace(honeycomb.getTraceID());
      const tstart = Date.now();
      const whoamiError = (resp) => {
        const hcPayload = {
          status: resp.status || "unknown",
          error: resp.error,
          duration: Date.now() - tstart,
        };

        if (honeycomb.sendAxiosGetSpan)
          honeycomb.sendAxiosGetSpan(
            JDP_API_WHOAMI.replace("/", ""),
            hcPayload,
            trace
          );

        handleLogout();
      };
      const whoamiOK = (status, data) => {
        const hcPayload = {
          status,
          duration: Date.now() - tstart,
        };
        if (honeycomb.sendAxiosGetSpan)
          honeycomb.sendAxiosGetSpan(
            JDP_API_WHOAMI.replace("/", ""),
            hcPayload,
            trace
          );

        updateUser(data);
        setInitializingUser(false);
      };

      if (keycloak?.authenticated) {
        // console.log("[KC]", keycloak);
        // TODO : JDP-2774 : contact_id can either from keycloak, or BE whoami
        /* KC data strcture:
          id: keycloak.tokenParsed.sub,
          login: keycloak.tokenParsed.preferred_username,
          name: keycloak.tokenParsed.name,
          first_name: keycloak.tokenParsed.given_name,
          last_name: keycloak.tokenParsed.family_name,
          email_address: keycloak.tokenParsed.email,
        */
        const promise = whoami();
        promise.then((resp) => {
          if (cancelled) return;
          if (resp?.error) {
            whoamiError(resp, trace, tstart);
          } else {
            const user = resp.data; // use whoami user data
            user.anonymous = false;
            user.keycloak = true;
            cookie.setKCSessionCookie(keycloak.token); // set KC cookie
            whoamiOK(resp.status, user);
          }
        });
      } else if (cookie.getSSOCookie()) {
        cookie.remove(cookie.KC_COOKIE_KEY); // rm KC cookie

        const promise = whoami();
        promise.then((resp) => {
          if (resp.data?.mimic?.error === "contact ID not found") {
            cookie.deleteContactIDCookie();
            window.location.reload(false);
          } else {
            if (cancelled) return;
            if (resp?.error) {
              whoamiError(resp, trace, tstart);
            } else {
              whoamiOK(resp.status, resp.data);
            }
          }
        });
      } else {
        cookie.remove(cookie.KC_COOKIE_KEY); // rm KC cookie on backend logout
        if (cancelled) return;
        // No cookie, no user to init
        setInitializingUser(false);
      }
      return () => {
        cancelled = true;
      };
    }, [keycloak]);

    return (
      <WrappedComponent
        {...props}
        currentUser={currentUser}
        handleLogout={handleLogout}
        handleLogin={handleLogin}
        handleKCLogin={handleKCLogin}
        initializingUser={initializingUser}
        updateUser={updateUser}
      />
    );
  };

  UserHOC.defaultProps = {
    honeycomb: {
      buildTrace: () => {},
      getTraceHeader: () => {},
      getTraceID: () => {},
      sendAxiosGetSpan: () => {},
      sendUiInteractionSpan: () => {},
      setHcUse: () => {},
    },
    // ldClient: null,
  };

  UserHOC.propTypes = {
    honeycomb: PropTypes.shape({
      sendAxiosGetSpan: PropTypes.func,
      sendUiInteractionSpan: PropTypes.func,
      buildTrace: PropTypes.func,
      getTraceID: PropTypes.func,
      getTraceHeader: PropTypes.func,
      setHcUser: PropTypes.func,
    }),
    // ldClient: PropTypes.shape({
    //   variation: PropTypes.func,
    // }),
  };

  return UserHOC;
};

export default withUser;
