import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Box, Typography, Paper } from "@material-ui/core";

import Downloader from "../Downloader/Downloader";

import classes from "./TableContainer.module.css";
import { useApi } from "../../hooks/useApi";
import { useSearchParams } from "../../hooks/useSearchParams";

import { RESTORE_STATUS_READY, RESTORE_STATUS_EXPIRED } from "./RestoreRequest";

const ImgBulkDownload = (props) => {
  const { honeycomb, ldClient } = props;
  const { useGenomeQuery, resetSearchCounts } = useApi(honeycomb);
  const { restoreid } = useSearchParams();

  const [status, setStatus] = useState(null);

  const { data, isLoading } = useGenomeQuery(ldClient);

  // console.log("[ImgBulk]", data);

  useEffect(() => {
    if (data) {
      // for title line display
      if (data.status) {
        const title = {
          head: `Your download request ${restoreid}`,
          body: "is pending",
          tail: "",
          color: classes.orange700,
          tscolor: "#111111",
          expdate: `Expiration Date: ${
            data.expiration_date
              ? data.expiration_date.substring(0, 10)
              : "unknown date"
          }`,
        };

        switch (data.status) {
          case RESTORE_STATUS_READY:
            title.head = `Files for download ${restoreid}`;
            title.body = "are ready";
            title.color = classes.moss500;
            title.tail = "!";
            break;
          case RESTORE_STATUS_EXPIRED:
            title.head = `Your download request ${restoreid}`;
            title.body = "has expired";
            title.color = classes.red500;
            title.tscolor = classes.red500;
            break;
          default:
            break;
        }
        setStatus(title);
      }
    }
  }, [data]);

  const downloader = () => {
    return (
      <Downloader
        isLoading={isLoading}
        {...props}
        resetSearchCounts={resetSearchCounts}
        imgBulk={data}
      />
    );
  };

  return (
    <>
      <Box style={{ margin: "0px 120px", minWidth: "800px" }}>
        <Box className={classes.titleBox}>
          <Typography variant="h1">
            {status ? (
              <div>
                {status.head}{" "}
                <span
                  style={{
                    color: status.color,
                  }}
                >
                  {status.body}
                </span>
                {status.tail}
              </div>
            ) : (
              `Files for download ${restoreid} ...`
            )}
            {status && (
              <span
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  color: status.tscolor,
                  fontFamily: "Public Sans",
                }}
              >
                {status.expdate}
              </span>
            )}
          </Typography>
        </Box>

        <Paper className={classes.downloaderBox} elevation={1}>
          {downloader()}
        </Paper>
      </Box>
    </>
  );
};

export default ImgBulkDownload;

ImgBulkDownload.defaultProps = {
  restoreRecords: null,
  honeycomb: null,
  ldClient: null,
  currentUser: {},
  setAppMessageID: () => {},
};

ImgBulkDownload.propTypes = {
  restoreRecords: PropTypes.objectOf(Object),
  honeycomb: PropTypes.shape(),
  ldClient: PropTypes.shape(),
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    keycloak: PropTypes.bool,
  }),
  setAppMessageID: PropTypes.func,
};
