import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import WarningIcon from "@material-ui/icons/Warning";

import Box from "@mui/material/Box";

import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  useGridApiRef,
  gridFilteredSortedRowIdsSelector, // https://mui.com/x/react-data-grid/filtering/#selectors - Get the id of the rows accessible after the filting process, contains the collapsed children
  // GridLinkOperator,
} from "@mui/x-data-grid-pro";

import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import GroupsIcon from "@mui/icons-material/Groups";
import CircularProgress from "@mui/material/CircularProgress";
import { base as ldbase } from "../../../utils/LDFFUtils";

import ControlButtons from "./ControlButtons";
import PagingUI from "../../TableContainer/Pagination/PagingUI";
import NotPiAlertDlg from "./Dialogs/NotPiAlertDlg";
import GrantAccessControls from "./GrantAccessControls";
import GrantSuccessConfirmationDlg from "./Dialogs/GrantSuccessConfirmationDlg";
import GrantFailureDlg from "./Dialogs/GrantFailureDlg";
import HttpErrorDlg from "./Dialogs/HttpErrorDlg";
import BusyDlg from "../../UI/Dialogs/BusyDialog";
import "./styles.css";
import classes from "./DataGrid.module.css";

import {
  JDP_API_PRIVATE_DATA_PARAM,
  collaborators,
  grantAccess,
} from "../../../utils/HttpClientProvider";

import {
  setLocalStorageMydataFilters,
  getLocalStorageMydataFilters,
} from "../../../utils/LocalStorageUtils";

import TransientTooltip from "../../UI/Tooltip/TransientTooltip/TransientTooltip";
import Tooltip from "../../UI/Tooltip/BasicTooltip";

import { MENU_MY_PROJECTS } from "../LeftDrawer";
import userTool from "../../../utils/UserUtils";

// for auto size table height
export const DATAGRID_TOOL_PADDING = 14;
export const DATAGRID_TABLE_META = {
  density: "standard",
  toolHeight: 36 + 2 * DATAGRID_TOOL_PADDING, // paddings
  headerHeight: 56,
  rowHeight: 52 + 1, // row height with 1px padding
};

// local helper for sorting collaborators by name
const sortByLower = (a, b) => {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
};

const PAGE_SIZE_ARRAY_BASE = [10, 20, 50, 100];
let PAGE_SIZE_ARRAY = [...PAGE_SIZE_ARRAY_BASE];
const PAGE_SZIE_DEFAULT = PAGE_SIZE_ARRAY[2];
const PAGE_DEFAULT = 1;

const DLG_NOT_PI = "DLG_NOT_PI";
const DLG_GRANT_SUCCESS = "DLG_GRANT_SUCCESS";
const DLG_GRANT_FAILED = "DLG_GRANT_FAILED";
const DLG_HTTP_ERROR = "DLG_HTTP_ERROR";

// topRect is the app's outer container, excluding the app footer
const DataGridContainer = ({
  topRect,
  menu,
  setSubmenu,
  currentUser,
  manage,
  rawData, // the entire dataset
  loading,
  honeycomb,
  ldClientMaster,
}) => {
  const [gridRows, setGridRows] = useState(rawData); // the dataset to display in the datagrid table, <= rawData
  const [selectedIds, setSelectedIds] = useState([]); // UI row selection state can be arbitrarily controll by this array

  const [dialogType, setDialogType] = useState(null);
  const [readyForGrant, setReadyForGrant] = useState(false);
  const [busy, setBusy] = useState(false);
  const [collabsOfFDID, setCollabsOfFDID] = useState(null);
  const [collabAnchorEl, setCollabAnchorEl] = useState(null);

  const [gridContainerRect, setGridContainterRect] = useState(null);
  const [filterModel, setFilterModel] = useState({});

  const contactId = `${userTool.userId(currentUser)}`;

  const formSubmitBtnRef = useRef();
  // Project or Manage page gridRows, after filtering
  const [activeRows, setActiveRows] = React.useState({
    length: 0,
    filters: 0,
    rows: [],
  });
  const apiRef = useGridApiRef();

  const admin_ff = Boolean(ldbase(ldClientMaster, "jdp-admins"));

  const sortEventHandler = (data) => {
    // console.log("[sortEventHandler]", data);
    honeycomb.sendUiInteractionSpan("grant-access-page-filter-applied", {
      filters: data,
    });
  };

  // on user-with-access panel close
  const handleCollabPanelClose = () => {
    setCollabAnchorEl(null);
    setCollabsOfFDID(null);
  };

  useEffect(() => {
    if (menu === MENU_MY_PROJECTS) {
      setGridRows(rawData);
    }
  }, [rawData, menu]);

  const history = useHistory();

  const handleGetAccessUsers = (event, fdid) => {
    event.stopPropagation();
    setCollabAnchorEl({ anchor: event.currentTarget, fdid });

    const promise = collaborators([fdid], honeycomb);
    promise.then((resp) => {
      if (!resp.error && resp.data) {
        const { data } = resp;
        if (data.length > 0) {
          if (data[0].collaborators) {
            setCollabsOfFDID(data[0].collaborators);
          } else if (data[0].error) {
            // JDP-2629 - handle error : [{"project_id":1006068,"error":"unauthorized"}]
            setCollabsOfFDID(data);
          }
        } else {
          setCollabsOfFDID([]);
        }
      }
    });
  };

  const BASE_COLUMNS = [
    {
      description: "JGI proposal ID",
      field: "proposalID",
      headerName: "Proposal ID",
      width: 90,
    },
    {
      description: "JGI final deliverable ID",
      field: "id",
      headerName: "Project ID",
      width: 90,
    },
    {
      description: "JGI final deliverable project name",
      field: "projName",
      headerName: "Project Name",
      width: 460,
      autoWidth: true,
      minWidth: 150,
    },
    {
      description: "PI name",
      field: "PI",
      headerName: "PI Name",
      valueGetter: (params) => {
        return params.row.PI?.name;
      },
      width: 150,
    },
    {
      description: "Project manager",
      field: "pmName",
      headerName: "Project Manager",
      width: 160,
    },
  ];

  const LEADING_COLOMNS = [
    ...BASE_COLUMNS,
    {
      description: "Users have access to projects",
      field: "accessUsers",
      headerName: "Users with Access",
      renderCell: (params) => {
        return (
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={(event) => {
              handleGetAccessUsers(event, params.row.id);
            }}
          >
            {collabAnchorEl?.fdid === params.row.id && !collabsOfFDID ? (
              <CircularProgress
                style={{ width: 20, height: 20, color: classes.lake500 }}
              />
            ) : (
              <Tooltip
                // iconType={ICON_NONE}
                title="Click here to see who has access to this project"
              >
                <GroupsIcon
                  style={{ width: 24, height: 24, color: classes.lake500 }}
                />
              </Tooltip>
            )}
          </Button>
        );
      },
      width: 160,
      sortable: false,
    },
  ];

  const gridColumns = [
    ...LEADING_COLOMNS,
    {
      description: "JGI Project type",
      field: "prodType",
      headerName: "Product Type",
      width: 240,
      minWidth: 120,
    },
    {
      description: "Project Status",
      field: "projStatus",
      headerName: "Project Status",
      // sortable: false,
      width: 160,
    },
    {
      field: "statusDate",
      headerName: "Status Date",
      width: 110,
    },
    {
      field: "dataUsage",
      headerName: "Data Usage",
      width: 110,
    },
    {
      field: "visibility",
      headerName: "Data Visibility",
      renderCell: (params) => {
        let label = params.row.visibility;
        const counts = params.row.visibilityCount;
        if (label === "Public") {
          if (
            counts.ap > counts.ap_data_available ||
            counts.sp > counts.sp_data_available
          ) {
            label = (
              <Tooltip title="Some parts of the project are still private">
                <Typography
                  style={{
                    cursor: "help",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontWeight: 700,
                    fontSize: "0.875rem",
                  }}
                >
                  {`${label}`}
                  <span style={{ fontWeight: 500 }}>*</span>
                </Typography>
              </Tooltip>
            );
          }
        }
        return label;
      },
      width: 120,
      // editable: true,
    },
    {
      field: "available_date",
      headerName: "Anticipated Public",
      renderCell: (params) => {
        // console.log("[DEBUG]", params.value);
        const tip =
          params.row.status_name === "Abandoned"
            ? "Project has been abandoned!"
            : params.value === "TBD"
            ? 'This value will be calculated as soon as the status of any child Sequencing Project or Analysis Project transitions to "Complete"'
            : null;
        return (
          <span title={tip} style={tip ? { cursor: "help" } : null}>
            {params.value}
          </span>
        );
      },
      width: 150,
      // editable: true,
    },
  ];

  const manageColumns = [
    ...BASE_COLUMNS.filter((d) => d.field !== "pmName"),
    {
      description: "User with access",
      field: "accessList",
      headerName: "Users with Access",
      renderCell: (params) => {
        if (!params.row.accessList) return null;
        const oldUsers = [];
        const newUsers = [];
        params.row.accessList.forEach((d) => {
          if (d.new) {
            newUsers.push(d);
          } else {
            oldUsers.push(d);
          }
        });

        // sort by name
        newUsers.sort(sortByLower);
        oldUsers.sort(sortByLower);
        const alist = [...newUsers, ...oldUsers];

        const content = alist.map((d, idx) => {
          const key = `${d.name.replace(/ /g, "_")}_${idx})`;
          return (
            <Typography
              key={key}
              style={{
                textTransform: "capitalize",
                fontWeight: d.new ? "bold" : "normal",
                padingRight: 2,
                fontSize: params.row.accessList.length > 2 ? 12 : 14,
              }}
            >
              {d.name}{" "}
            </Typography>
          );
        });
        return (
          <Box
            id="data-grid-collaborators"
            sx={{
              maxHeight: 40,
              width: "100%",
              overflowY: "auto",
            }}
          >
            {content}
          </Box>
        );
      },
      width: 250,
    },
    {
      field: "accessList_csv",
      headerName: "Users with Access",
      hidden: true,
    },
    {
      description: "Project managers",
      field: "managers",
      headerName: "Project Manager",
      valueGetter: (params) =>
        params.row.managers?.map((d) => d.name).join("; "),
      width: 250,
    },
  ];

  const filterSecName = manage ? "manage" : "projects";
  const activeColumns = manage ? manageColumns : gridColumns;
  const filterItems =
    filterModel && filterModel[filterSecName]
      ? filterModel[filterSecName].items.filter((d) => d.value)
      : null;

  const handleFilterChange = (model) => {
    const fModal = { ...filterModel };
    fModal[filterSecName] = model;
    honeycomb.sendUiInteractionSpan("grant-access-filter-changed", {
      filters: {
        section: filterSecName,
        model: JSON.stringify(model),
      },
    });
    setFilterModel(fModal);
    setLocalStorageMydataFilters(currentUser, "myprojects", fModal);
  };

  useEffect(() => {
    const fdata = getLocalStorageMydataFilters(currentUser, "myprojects");
    if (fdata) {
      setFilterModel(fdata);
      honeycomb.sendUiInteractionSpan(
        "grant-access-localstorage-filters-loaded",
        {
          filters: fdata,
        }
      );
    }
  }, []);

  const gridContainerBoxRef = useRef(null); // for table height to reach app footer
  const gridRef = useRef(null); // to get current datagrid component width

  const initPageConfig = {
    page: PAGE_DEFAULT,
    pageSize: PAGE_SZIE_DEFAULT,
    pageTotal: 1,
    active: false,
  };
  const [pageConfig, _setPageConfig] = useState(initPageConfig);

  const [gridBoxHeight, setGridBoxHeight] = useState(200);

  // table header, row, and toolbar heights
  const [tableMeta, setTableMeta] = useState(DATAGRID_TABLE_META);

  const _setTableMeta = (density) => {
    const { value, headerHeight, rowHeight } = density;

    if (value !== tableMeta.density) {
      const meta = {
        ...tableMeta,
        density: value,
        headerHeight,
        rowHeight: rowHeight + 1,
      };
      setTableMeta(meta);
    }
  };

  const setPageConfig = (page, pageSize, active = true) => {
    const pageTotal = Math.ceil(
      (activeRows.length > 0 ? activeRows.length : gridRows.length) / pageSize
    );
    if (
      page !== pageConfig.page ||
      pageSize !== pageConfig.pageSize ||
      pageTotal !== pageConfig.pageTotal ||
      active !== pageConfig.active
    ) {
      const pconf = { ...pageConfig, pageTotal, page, pageSize, active };
      _setPageConfig(pconf);
    }
  };

  const setHTTPError = (message, status) => {
    setDialogType({
      type: DLG_HTTP_ERROR,
      message,
      status,
    });
  };

  useEffect(() => {
    if (gridRows?.length > 0) {
      const psizeMax =
        activeRows.length > 0 ? activeRows.length : gridRows.length;
      PAGE_SIZE_ARRAY = [...PAGE_SIZE_ARRAY_BASE];
      if (psizeMax > PAGE_SIZE_ARRAY[PAGE_SIZE_ARRAY.length - 1]) {
        PAGE_SIZE_ARRAY.push(psizeMax);
      }

      setPageConfig(PAGE_DEFAULT, pageConfig.pageSize);
    }
  }, [gridRows, activeRows]);

  useEffect(() => {
    // deselection rows that are not in the filtered dataset
    if (selectedIds.length > 0 && filterItems?.length > 0) {
      const newSelectedIds = selectedIds.filter((d) =>
        activeRows.rows.includes(d)
      );
      if (newSelectedIds.length !== selectedIds.length) {
        apiRef.current.setRowSelectionModel(newSelectedIds);
      }
    }
  }, [activeRows]);

  const rowsAfterFiltering = () => {
    if (apiRef.current && Object.keys(apiRef.current).length > 0) {
      // data set after applying filter, = full dataset if no filter is applied
      const filtered = gridFilteredSortedRowIdsSelector(apiRef);
      if (filtered.length !== activeRows.length) {
        setActiveRows({
          ...activeRows,
          length: filtered.length,
          rows: filtered,
        });
      }
    }
  };

  useEffect(() => {
    rowsAfterFiltering();
    // ref : https://codesandbox.io/p/sandbox/zen-panka-ex5d6?file=%2Fsrc%2FDemo.tsx%3A21%2C12-26%2C11
    if (apiRef.current.subscribeEvent) {
      apiRef.current.subscribeEvent("sortModelChange", (params) => {
        sortEventHandler(params);
      });
    }
  }, [apiRef.current]);

  const resetHeight = () => {
    if (gridContainerBoxRef?.current && topRect) {
      const rect = gridContainerBoxRef.current.getBoundingClientRect();
      setGridBoxHeight(topRect.bottom - rect.top); // vertical distance from rect.top to app.footer.top
    }
  };

  useEffect(() => {
    if (gridRef.current) {
      const { width, height } = gridRef.current.getBoundingClientRect();
      if (!gridContainerRect || gridContainerRect.width !== width) {
        setGridContainterRect({
          width,
          height,
        });
      }
    }
  }, [gridRef.current, topRect]);

  useEffect(() => {
    resetHeight();
  }, [gridContainerBoxRef.current, topRect]);

  const infoLabelSeparator = (
    <Typography style={{ padding: "0px 12px" }}>|</Typography>
  );
  const infoLabelNum = (num) => (
    <Typography style={{ fontWeight: 600, paddingRight: 4 }}>
      {" "}
      {num}{" "}
    </Typography>
  );

  const toolbarRef = useRef(null);
  const csvDataColumns = manage
    ? manageColumns.filter((d) => d.field !== "accessList").map((d) => d.field)
    : gridColumns.filter((d) => d.field !== "accessUsers").map((d) => d.field);

  const CustomToolbar = () => {
    const toolItemGap = 1;
    return (
      <GridToolbarContainer
        ref={toolbarRef}
        sx={{ padding: `${DATAGRID_TOOL_PADDING}px` }}
      >
        <GridToolbarColumnsButton
          className="data-grid-toolbar-icon"
          sx={{ marginRight: toolItemGap }}
        />
        <GridToolbarFilterButton
          className="data-grid-toolbar-icon"
          sx={{ marginRight: toolItemGap }}
        />
        <GridToolbarDensitySelector
          className="data-grid-toolbar-icon"
          sx={{ marginRight: toolItemGap }}
        />
        <GridToolbarExport
          className="data-grid-toolbar-icon"
          csvOptions={{
            fields: csvDataColumns,
            fileName: manage
              ? `${contactId}_manage_projects`
              : `${contactId}_myprojects`,
          }}
        />
        <div style={{ display: "flex", marginLeft: "auto" }}>
          {selectedIds?.length > 0 ? (
            <>
              {infoLabelNum(selectedIds.length)}
              <Typography>
                {" "}
                row
                {selectedIds?.length > 1 ? "s" : ""} selected
              </Typography>
              {infoLabelSeparator}
            </>
          ) : null}

          {filterItems?.length > 0 ? (
            <>
              {infoLabelNum(activeRows.length)}
              <Typography>
                {" "}
                row{activeRows.length > 1 ? "s" : ""} after filter{" "}
              </Typography>
              {infoLabelSeparator}
            </>
          ) : null}

          {rawData?.length > 0 ? (
            <>
              {" "}
              {infoLabelNum(gridRows.length)}
              <Typography>
                {} total row{gridRows.length > 1 ? "s" : ""}
              </Typography>
            </>
          ) : null}

          <div style={{ marginLeft: 100 }}>
            <PagingUI
              pageSizeLabel="Rows per page"
              pageTotal={pageConfig.pageTotal}
              pageSizeChanged={(size) => {
                honeycomb.sendUiInteractionSpan(
                  "grant-access-page-size-changed-by-user"
                );
                setPageConfig(1, size);
              }}
              pageNumChanged={(page) => {
                honeycomb.sendUiInteractionSpan(
                  "grant-access-page-number-changed-by-user"
                );
                setPageConfig(page, pageConfig.pageSize);
              }}
              pageSizeArray={PAGE_SIZE_ARRAY}
              pageSizeValue={pageConfig.pageSize}
              pageNumberValue={pageConfig.page}
            />
          </div>
        </div>
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    if (toolbarRef.current) {
      const toolHeight = toolbarRef.current.clientHeight;
      if (toolHeight !== tableMeta.toolHeight) {
        const meta = {
          ...tableMeta,
          toolHeight,
        };
        setTableMeta(meta);
      }
    }
  }, [toolbarRef.current]);

  /* istanbul ignore next */
  const handleRefineAndDownload = () => {
    honeycomb.sendUiInteractionSpan("clicked-mydataportal-select-and-download");
    const url = `/search?q=${selectedIds.join(
      "|"
    )}&f=project_id&${JDP_API_PRIVATE_DATA_PARAM}`;
    history.push(url);
  };

  /* istanbul ignore next */
  const handleManageFiles = () => {
    // trigger form submission
    if (admin_ff && selectedIds.length > 0) {
      formSubmitBtnRef.current.click();
    }
  };
  /* istanbul ignore next */
  const handleDialogCancel = () => {
    setDialogType(null);
  };

  // TODO: return true for co-pi too??
  const isPI = (pid) => `${pid}` === contactId;

  /* istanbul ignore next */
  const prepGrantUI = () => {
    if (dialogType !== null) {
      setDialogType(null);
    }

    setBusy(true);

    if (rawData.length > 0) {
      const toGrantDataMap = {};
      const toGrantData = [];
      let idx = 0;
      rawData.forEach((d) => {
        if (selectedIds.includes(d.id)) {
          if (isPI(d.pi_contact_id)) {
            toGrantDataMap[d.id] = idx;
            toGrantData.push(d);
            idx += 1;
          }
        }
      });

      const forAccessList = toGrantData.filter((d) => !d.accessList);
      const fdids = forAccessList.map((d) => d.id);
      // fdids.length: 10 => 1.8s; 20 => 3s; 200 => 25s; 400 => 48s;  >=700 TOO LONG URL
      if (forAccessList.length > 0) {
        const promise = collaborators(fdids, honeycomb);
        promise
          .then((resp) => {
            if (resp.error) {
              setHTTPError(resp.error, resp.status);
            } else if (!resp.data) {
              // no projects returned from BE; no collaborators yet
              setSubmenu("Manage Access");
              setGridRows(toGrantData);
              setReadyForGrant(true);
            } else {
              const { data } = resp;
              if (data.length > 0) {
                data.forEach((d) => {
                  const names = []; // avoid duplicates
                  const collabs = [];
                  d.collaborators.forEach((cd) => {
                    // TODO - here we have cd.level, can be "Final Deliverable", "proposal" etc. what should we do?
                    if (!isPI(cd.contact_id)) {
                      const collabName = `${cd.first_name} ${cd.last_name}`;
                      if (!names.includes(collabName)) {
                        names.push(collabName);
                        collabs.push({
                          name: collabName,
                          email_address: cd.email,
                        });
                      }
                    }
                  });
                  toGrantData[toGrantDataMap[d.project_id]].accessList =
                    collabs;
                  toGrantData[toGrantDataMap[d.project_id]].accessList_csv =
                    names.join(" | ");
                  toGrantData[toGrantDataMap[d.project_id]].managers = [
                    {
                      name: `${d.project_manager.first_name} ${d.project_manager.last_name}`,
                      email: d.project_manager.email,
                    },
                  ];
                });
                setSubmenu("Manage Access");
                setGridRows(toGrantData);
                setReadyForGrant(true);
              }
            }
          })
          .finally(() => {
            setBusy(false);
          });
      } else {
        setSubmenu("Manage Access");
        setGridRows(toGrantData);
        setReadyForGrant(true);
        setBusy(false);
      }
    }
  };

  // "Manage Access" btn click
  const manageAccessHandler = () => {
    // setSubmenu("Manage Access");
    honeycomb.sendUiInteractionSpan("clicked-manage-access-button");
    if (readyForGrant) {
      setReadyForGrant(false);
    }

    const piProjects = [];
    const nopiProjects = [];

    rawData.forEach((d) => {
      if (selectedIds.includes(d.id)) {
        if (isPI(d.pi_contact_id)) {
          piProjects.push(d);
        } else {
          nopiProjects.push(d);
        }
      }
    });

    if (nopiProjects.length > 0) {
      setDialogType({ type: DLG_NOT_PI });
    } else {
      prepGrantUI();
    }
  };

  /* istanbul ignore next */
  const addUserToAccessListHelper = (id, name, email) => {
    for (let i = 0; i < gridRows.length; i++) {
      const row = gridRows[i];
      if (row.id === id) {
        const map = {};
        row.accessList.forEach((d) => {
          map[d.name] = d;
        });
        if (!Object.keys(map).includes(name)) {
          row.accessList.push({
            name,
            email,
            new: true,
          });
        } else {
          map[name].new = true;
        }

        break;
      }
    }
  };

  /* istanbul ignore next */
  const doGrantAccess = (data) => {
    if (dialogType) {
      setDialogType(null);
    }

    const payload = {
      project_ids: [],
      orcid_ids: [],
    };
    Object.keys(data.users).forEach((d) => {
      payload.orcid_ids.push(d);
    });
    data.data.forEach((d) => {
      payload.project_ids.push(d.id);
    });

    honeycomb.sendUiInteractionSpan("grant-access", { payload });
    setBusy(true);
    const promise = grantAccess(payload, honeycomb);
    promise.then((resp) => {
      /**
       * 
       * {
              "status": 200,
              "data": [
                  {
                      "project_id": 1448163,
                      "contacts": [
                          {
                              "orcid_id": "0000-0002-4229-8314",
                              "message": "access has been granted"
                          },
                      ]
                  }
              ]
          }
       */
      const errors = [];
      if (resp.data) {
        const confirmationData = {
          good: {
            users: {},
            projects: [],
          },
          bad: {
            users: [],
            projects: [],
          },
        };
        resp.data.forEach((d) => {
          const fdid = d.project_id;
          if (!d.error) {
            // TODO: not sure if there can be failed case, and if failed case is flagged by d.error!
            if (!confirmationData.good.projects.includes(d.project_id)) {
              confirmationData.good.projects.push(d.project_id);
            }

            d.contacts.forEach((ud) => {
              const { orcid_id } = ud;
              const user = data.users[orcid_id];
              const { first_name, last_name, email } = user;
              const name = `${first_name} ${last_name}`;
              addUserToAccessListHelper(fdid, name, email);
              confirmationData.good.users[orcid_id] = {
                first_name,
                last_name,
                email,
              };
            });

            setGridRows([...gridRows]);
          } else {
            if (!confirmationData.bad.projects.includes(d.project_id)) {
              confirmationData.bad.projects.push(d.project_id);
            }
            d.contacts.forEach((ud) => {
              const { orcid_id } = ud;
              confirmationData.bad.users[orcid_id] = d.error;
            });
            errors.push({ fdid, error: d.error });
          }
        });
        setBusy(false);
        setDialogType({
          type: DLG_GRANT_SUCCESS,
          data: confirmationData,
        });
      }

      // else {
      //   // TODO : error handleing dialog here!
      //   // eslint-disable-next-line no-console
      //   console.log("[doGrandAccess - error]", JSON.stringify(resp));
      // }
    });
  };

  const userWithAccessPanel = () => {
    const contact_id = parseInt(
      currentUser.mimic?.contact_id || currentUser.contact_id,
      10
    );
    const collabs =
      collabsOfFDID && collabsOfFDID.filter((d) => d.contact_id !== contact_id);

    if (collabAnchorEl && collabs) {
      if (collabs.length === 0 || (collabs.length === 1 && collabs[0].error)) {
        const collabError = collabs.length === 1 ? collabs[0].error : null;
        return (
          <Menu
            anchorEl={collabAnchorEl.anchor}
            open={Boolean(collabAnchorEl)}
            onClose={handleCollabPanelClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Box style={{ padding: 12, maxWidth: 180 }}>
              <Typography>
                <WarningIcon
                  style={{
                    position: "relative",
                    color: classes.orange600,
                    marginRight: 6,
                    top: 6,
                  }}
                />
                {collabError === "unauthorized"
                  ? "You are not authorized to see the list of other users with access.  Please contact the PI or Project Manager for user-related information."
                  : collabError ||
                    "No other users with access privilege to this project"}
              </Typography>
            </Box>
          </Menu>
        );
      }
      const uiData = {};
      collabs.forEach((d) => {
        const name = `${d.first_name} ${d.last_name}`;
        const orcid = d.orcid_id
          ? d.orcid_id?.replace("https://orcid.org/", "")
          : "NA";

        uiData[name] = {
          contact_id: d.contact_id,
          tip: [
            ["Name", name],
            ["Contact id", d.contact_id],
            ["ORCID", orcid],
            ["Email", d.email],
            ["User role", d.role],
          ],
        };
      });

      return (
        <Menu
          anchorEl={collabAnchorEl.anchor}
          open={Boolean(collabAnchorEl)}
          onClose={handleCollabPanelClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Box style={{ maxHeight: 290, overflow: "auto" }}>
            <List>
              {Object.keys(uiData)
                .sort()
                .map((d, idx) => {
                  const tip = (
                    <List>
                      {uiData[d].tip.map((td, tdidx) => {
                        const k = `collab-tip-key-${tdidx}`;
                        return (
                          <ListItem key={k} style={{ padding: "2px 6px" }}>
                            <Typography
                              style={{
                                width: 80,
                                fontWeight: 800,
                                fontSize: 12,
                              }}
                            >
                              {td[0]}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 12,
                              }}
                            >
                              {td[1]}
                            </Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  );

                  const aid = `user-with-access-${idx}-${d.contact_id}`;
                  return (
                    <TransientTooltip
                      key={aid}
                      child={
                        <ListItem style={{ padding: "4px 14px" }}>{d}</ListItem>
                      }
                      labelConf={[
                        {
                          type: "title",
                          value: tip,
                        },
                      ]}
                      bottomOffset={-10}
                      open={false}
                      placement="right-start"
                    />
                  );
                })}
            </List>
          </Box>
        </Menu>
      );
    }
  };

  // console.log("[DG]", filters);
  const dataGridInitialState = {
    columns: {
      columnVisibilityModel: {
        accessList_csv: false,
      },
    },
  };

  const showDialog = () => {
    switch (dialogType?.type) {
      case DLG_NOT_PI:
        return (
          <NotPiAlertDlg
            uid={contactId}
            data={rawData.filter((d) => selectedIds.includes(d.id))}
            cancelBtnFn={handleDialogCancel}
            proceedBtnFn={prepGrantUI}
          />
        );
      case DLG_GRANT_SUCCESS:
        return (
          <GrantSuccessConfirmationDlg
            data={dialogType?.data}
            continueBtnFn={handleDialogCancel}
          />
        );
      case DLG_GRANT_FAILED:
        return (
          <GrantFailureDlg
            data={dialogType?.data}
            cancelBtnFn={handleDialogCancel}
          />
        );
      case DLG_HTTP_ERROR:
        return (
          <HttpErrorDlg
            message={dialogType.message}
            status={dialogType.status}
            cancelBtnFn={() => {
              setDialogType(null);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      {manage && readyForGrant ? (
        <GrantAccessControls
          rows={rawData.filter(
            (d) => selectedIds.includes(d.id) && isPI(d.pi_contact_id)
          )}
          doGrantAccess={doGrantAccess}
          busyHandler={setBusy}
          setHTTPError={setHTTPError}
          honeycomb={honeycomb}
          currentUser={currentUser}
        />
      ) : (
        <ControlButtons
          selected={selectedIds}
          onManageAccess={manageAccessHandler}
          handleRefineAndDownload={handleRefineAndDownload}
          handleManageFiles={handleManageFiles}
          honeycomb={honeycomb}
          admin={admin_ff}
        />
      )}

      <Box
        ref={gridContainerBoxRef}
        sx={{
          // height: 400, // Math.min(tableHeight, tabelHeightMax),
          width: "100%",
          marginTop: "24px",
        }}
      >
        <Box sx={{ display: "flex", height: gridBoxHeight, minHeight: 100 }}>
          {/* need a minHeight to avoid the  "useResizeContainer - The parent DOM element of the data grid has an empty height." error when window height is too small */}
          {loading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : gridRows.length === 0 ? (
            <Typography
              style={{ fontSize: 18, fontStyle: "italic", opacity: 0.5 }}
            >
              No project data{" "}
            </Typography>
          ) : (
            <>
              <DataGridPro
                apiRef={apiRef}
                ref={gridRef}
                rows={gridRows}
                columns={activeColumns}
                initialState={dataGridInitialState}
                // filterModel={{
                //   items: filterItems,
                // }}

                filterModel={
                  filterModel && filterModel[filterSecName]
                    ? filterModel[filterSecName]
                    : { items: [] }
                }
                onFilterModelChange={handleFilterChange}
                // columnVisibilityModel={}
                // components={{ Toolbar: CustomToolbar, Footer: CustomFooter }}
                components={{ Toolbar: CustomToolbar }}
                checkboxSelection
                rowsPerPageOptions={PAGE_SIZE_ARRAY.filter(
                  (d) => typeof d !== "string"
                )}
                paginationModel={{
                  page: pageConfig.active ? pageConfig.page - 1 : 0,
                  pageSize: pageConfig.active ? pageConfig.pageSize : 100,
                }}
                // pageSize={pageConfig?.pageSize}
                // page={pageConfig ? pageConfig.page - 1 : 0} // data-grid page is 0-based
                // onCellClick={handleCellClick}

                // onFilterModelChange={handleFilterChange}
                onRowSelectionModelChange={(ids) => {
                  // console.log("[onSelectionModelChange]", ids?.length);
                  setSelectedIds(ids);
                }}
                selectionModel={selectedIds}
                disableColumnMenu // hide disable the kebab menu in column header
                onStateChange={(state) => {
                  _setTableMeta(state.density);
                  rowsAfterFiltering(); // need to refresh the possible filtered results here
                }}
                getRowHeight={() => "auto"} // auto adjust row content to mutil-lines if too narrow
                pagination={pageConfig.active}
                // hideFooterPagination // keep footer panel to show selection info
                hideFooter
                // checkboxSelectionVisibleOnly // select all checkbox only select rows on current page
              />
            </>
          )}
        </Box>
      </Box>
      {showDialog()}
      {busy && <BusyDlg message="busy ..." />}
      {userWithAccessPanel()}

      {/* SDM edit display location request */}
      {admin_ff && selectedIds.length > 0 && (
        <form
          id="manage-files-form"
          action="https://jamo.jgi.doe.gov/metadata/projectfiles"
          method="POST"
          style={{ display: "none" }}
          target="_blank"
        >
          <input
            type="hidden"
            name="identifier"
            value="metadata.final_deliv_project_id"
          />
          <input type="hidden" name="values" value={selectedIds.join(",")} />
          <input
            ref={formSubmitBtnRef}
            type="submit"
            name="submit"
            value="submit"
          />
        </form>
      )}
    </Box>
  );
};

export default DataGridContainer;

DataGridContainer.defaultProps = {
  topRect: null,
  currentUser: {},
  rawData: [],
  honeycomb: null,
};

DataGridContainer.propTypes = {
  menu: PropTypes.string.isRequired,
  manage: PropTypes.bool.isRequired,
  topRect: PropTypes.shape(),
  currentUser: PropTypes.shape(),
  setSubmenu: PropTypes.func.isRequired,
  rawData: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool.isRequired,
  honeycomb: PropTypes.shape(),
  ldClientMaster: PropTypes.shape().isRequired,
};
