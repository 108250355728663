/* istanbul ignore file */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ButtonMui from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LinearProgress from "@mui/material/LinearProgress";

import ErrorIcon from "@mui/icons-material/Error";

import { getNativeButton } from "../Button/Button";
import JDPHelpIcon from "../UI/Icons/JDPHelpIcon";
import filterClasses from "../FilterContainer/Filters/Filters.module.css";
import { HREF_DOC_ADVANCED_SEARCH } from "../../utils/URLConstants";
import PulldownMenu from "../UI/PulldownMenu/PulldownMenu";
import { advancedSearchKeys } from "../../utils/HttpClientProvider";
import { useSearchParams } from "../../hooks/useSearchParams";

import Tooltip, { ICON_NONE } from "../UI/Tooltip/Tooltip";
import YearUI from "./YearUI";
import DateUI from "./DateUI";
import ValueUI from "./ValueUI";

import { base as ldbase, AQBFieldHelperText } from "../../utils/LDFFUtils";

import {
  isJGIValidDate,
  isJGIValidYear,
  jgiStartDate,
  currentYYYYMMDD,
  toYYYYMMDD,
  validDateRange,
} from "../../utils/TimeUtils";

import theme from "../../themes/theme.module.css";

const ADV_SEARCH_OP = ["AND", "OR", "NOT"];
const ALL_FIELDS = "All Fields";
const ERROR_MSG =
  "We were not able to process your query. Please check the type or the syntax of your query and try again.";

const AdvancedSearch = ({
  isLoading,
  error,
  open,
  onClose,
  honeycomb,
  topRect,
  ldClient,
  currentUser,
}) => {
  const [searchMeta, setSearchMeta] = useState([{ value: "" }]);
  const [keyMap, setKeyMap] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [field2TitleMap, setField2TitleMap] = useState({});

  const history = useHistory();
  const { q } = useSearchParams();

  useEffect(() => {
    const promise = advancedSearchKeys();
    promise.then((resp) => {
      const kMap = {
        desc2name: {
          "All Fields": { name: ALL_FIELDS },
        },
      };
      resp?.forEach((d) => {
        const { description, name, type, range } = d;
        kMap.desc2name[description] = { name, type, range };
      });

      // JDP-2690 - date fields test data
      const debugData = ldbase(ldClient, "aqb-date-year-data");
      if (debugData?.length > 0) {
        debugData.forEach((d) => {
          const { description, name, type, range } = d;
          kMap.desc2name[description] = { name, type, range };
        });
      }

      setKeyMap(kMap);
    });

    honeycomb.sendUiInteractionSpan("adv-search-ui-opened");
    // pulldown placeholder text
    const aqbHelpTextMap = AQBFieldHelperText(ldClient);
    if (aqbHelpTextMap) {
      const previewer = currentUser?.email_address;
      const f2t = {};
      Object.keys(aqbHelpTextMap).forEach((k) => {
        // k is the display string (description in the JSON returned by BE /search/fields/) in the field pulldown
        const data = aqbHelpTextMap[k];
        if (previewer && previewer in data) {
          data.title = data[previewer];
        }
        const { title } = data;
        if (title) {
          f2t[k] = title;
        }
      });
      setField2TitleMap(f2t);
    }
  }, []);

  useEffect(() => {
    if (error) {
      setErrorMsg(ERROR_MSG);
    } else {
      setErrorMsg(null);
    }
  }, [error]);

  useEffect(() => {
    if (q && JSON.stringify(searchMeta[0]) === '{"value":""}' && keyMap) {
      const initd = {
        key: ALL_FIELDS,
        value: q,
      };
      setSearchMeta([initd]);
    }
  }, [q, keyMap]);

  // style for the 1st row read-only TextField
  const inputSizeStyle = {
    "& .MuiInputBase-input": {
      height: 12,
      padding: 1,
      border: `solid 1px ${theme.grey500}`,
      borderRadius: 1,
      backgroundColor: theme.grey100,
      color: theme.grey700,
    },
  };

  // style for the 1 or more search value TextField
  const inputStyle = {
    "& .MuiInputBase-input": {
      ...inputSizeStyle["& .MuiInputBase-input"],
    },
    color: theme.grey700,
    "& .MuiInputBase-input:hover": {
      border: `solid 1px ${theme.lake500}`,
    },
    "& .MuiInputBase-input:active": {
      border: `solid 1px ${theme.lake500}`,
    },
  };
  delete inputStyle["& .MuiInputBase-input"].backgroundColor;

  const iconStyle = {
    position: "relative",
    color: theme.lake500,
    cursor: "pointer",
    width: 26,
    height: 26,
    top: -2,
  };

  const updateValue = (index, value, type = "value") => {
    const meta = [...searchMeta];
    if (type === "value") {
      meta[index].value = value;
    } else if (type === "operator") {
      meta[index].operator = value;
    } else {
      meta[index].key = value;
    }
    setSearchMeta(meta);
  };

  const ICON_ADD = "ICON_ADD";
  const ICON_REMOVE = "ICON_REMOVE";
  const add_remove = (index, type) => {
    const meta = [...searchMeta];
    if (type === ICON_ADD) {
      meta.splice(index + 1, 0, { value: "" });
    } else {
      meta.splice(index, 1);
    }

    setSearchMeta(JSON.parse(JSON.stringify(meta)));
  };

  const validateDateValue = (desc, value) => {
    // if a single date value is valid

    if (keyMap.desc2name[desc].type === "date") {
      return isJGIValidDate(value);
    }
    return isJGIValidYear(value);
  };

  const validateDateRange = (from, to) => {
    if (!from || !to) {
      // is valid if either end is null
      return true;
    }
    return validDateRange(from, to);
  };

  const isDateRangeHasValidValue = (d) => {
    const { value, key } = d;
    if (typeof value === "object") {
      const { from, to } = value;
      return (
        (!from || validateDateValue(key, from)) &&
        (!to || validateDateValue(key, to)) &&
        validateDateRange(from, to)
      );
    }
    return false;
  };

  const learnMoreUI = (
    <JDPHelpIcon size="large">
      <Typography
        className={`${filterClasses.bodySmall} ${filterClasses.filterHelpText}`}
        noWrap
        style={{ position: "relative", top: 6 }}
      >
        <Link
          className={filterClasses.filterHelpLink}
          href={HREF_DOC_ADVANCED_SEARCH}
          target="_blank"
          data-testid="advSearchBuilderHelp"
          onClick={() => {
            honeycomb.sendUiInteractionSpan("adv-search-learn-more-clicked");
          }}
        >
          Learn more
        </Link>{" "}
        about value types and examples
      </Typography>
    </JDPHelpIcon>
  );

  const endColumnStyle = {
    width: searchMeta.length > 1 ? 60 : 30,
    position: "relative",
    left: -18,
  };

  const getValueUI = (idx, value, style) => {
    const getLabel = (val) => (
      <Typography
        style={{
          position: "relative",
          top: 6,
          paddingRight: 6,
          paddingLeft: 10,
          fontSize: 14,
        }}
      >
        {val}
      </Typography>
    );

    const getYearValueUI = (range) => {
      return range ? (
        <>
          <Grid item>{getLabel("FROM")}</Grid>
          <Grid item>
            <YearUI
              style={style}
              valid={
                searchMeta[idx]?.value?.validRange === true
                  ? true
                  : searchMeta[idx]?.value?.validRange === false
                  ? false
                  : null
              }
              callback={(val) => {
                const meta = [...searchMeta];
                const { to } = meta[idx].value || {};
                const newRange = { from: val, to };
                const rangeData = { key: meta[idx].key, value: newRange };
                newRange.validRange =
                  (val || to) && isDateRangeHasValidValue(rangeData);
                updateValue(idx, newRange);
              }}
            />
          </Grid>
          <Grid item>{getLabel("To")}</Grid>
          <Grid item>
            <YearUI
              style={style}
              valid={
                searchMeta[idx]?.value?.validRange === true
                  ? true
                  : searchMeta[idx]?.value?.validRange === false
                  ? false
                  : null
              }
              callback={(val) => {
                const meta = [...searchMeta];
                const { from } = meta[idx].value || {};
                const newRange = { from, to: val };
                const rangeData = { key: meta[idx].key, value: newRange };
                newRange.validRange =
                  (val || from) && isDateRangeHasValidValue(rangeData);
                updateValue(idx, newRange);
              }}
            />
          </Grid>
        </>
      ) : (
        <Grid item>
          <YearUI
            style={style}
            callback={(val) => {
              updateValue(idx, val);
            }}
          />
        </Grid>
      );
    };

    const getDateValueUI = (range) => {
      return range ? (
        <>
          <Grid item>{getLabel("FROM")}</Grid>
          <Grid item>
            <DateUI
              style={style}
              valid={
                searchMeta[idx]?.value?.validRange === true
                  ? true
                  : searchMeta[idx]?.value?.validRange === false
                  ? false
                  : null
              }
              callback={(val) => {
                const meta = [...searchMeta];
                const { to } = meta[idx].value || {};
                const newRange = { from: val, to };
                const rangeData = { key: meta[idx].key, value: newRange };
                newRange.validRange =
                  (val || to) && isDateRangeHasValidValue(rangeData);
                updateValue(idx, newRange);
              }}
            />
          </Grid>
          <Grid item>{getLabel("TO")}</Grid>
          <Grid item>
            <DateUI
              style={style}
              valid={
                searchMeta[idx]?.value?.validRange === true
                  ? true
                  : searchMeta[idx]?.value?.validRange === false
                  ? false
                  : null
              }
              callback={(val) => {
                const meta = [...searchMeta];
                const { from } = meta[idx].value || {};
                const newRange = { from, to: val };
                const rangeData = { key: meta[idx].key, value: newRange };
                newRange.validRange =
                  (val || from) && isDateRangeHasValidValue(rangeData);
                updateValue(idx, newRange);
              }}
            />
          </Grid>
        </>
      ) : (
        <Grid item>
          <DateUI
            style={style}
            callback={(val) => {
              updateValue(idx, val);
            }}
          />
        </Grid>
      );
    };

    const { key } = searchMeta[idx];
    let inputUI = null;

    if (key && ["year", "date"].includes(keyMap?.desc2name[key].type)) {
      const { type, range } = keyMap.desc2name[key];
      inputUI = (
        <Grid container wrap="nowrap">
          {type === "year" ? getYearValueUI(range) : getDateValueUI(range)}
        </Grid>
      );
    } else {
      inputUI = (
        <ValueUI
          style={style}
          value={typeof value === "string" ? value : ""}
          disabled={!key}
          callback={(val) => {
            updateValue(idx, val.trimStart());
          }}
        />
      );
    }

    const helpText = key ? field2TitleMap[key] : "This is the description area";

    return (
      <Grid
        container
        sx={{
          width: "auto",
        }}
      >
        <Grid item xs={12}>
          {inputUI}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#949494",
              fontSize: 12,
              paddingLeft: key && keyMap.desc2name[key].range ? 60 : 4,
            }}
          >
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: helpText,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getSearchParamUI = () => {
    const adv_ops = ADV_SEARCH_OP.map((d) => ({ label: d }));

    return searchMeta.map((d, idx) => {
      const key = `adv-value-ui-${idx}`;
      const { value, operator } = d;
      const valueStyle = { ...inputStyle };
      if (value) {
        const istyle = {
          ...valueStyle["& .MuiInputBase-input"],
          border: `solid 1px ${theme.lake500}`,
        };
        valueStyle["& .MuiInputBase-input"] = istyle;
      }

      const fields = keyMap
        ? Object.keys(keyMap.desc2name)
            .sort()
            .map((desc) => ({ label: desc }))
        : null;

      return (
        <React.Fragment key={key}>
          {/* Column 1; optional; operator */}
          <Grid item lg={2} md={2} sm={2} xs={3}>
            {idx > 0 && (
              <PulldownMenu
                items={adv_ops}
                intVal={operator || ADV_SEARCH_OP[0]}
                onSelect={(val) => {
                  updateValue(idx, val, "operator");
                }}
                windowWidth={topRect?.width}
              />
            )}
          </Grid>

          {/* Column 2; field pulldown */}
          <Grid item lg={3} md={4} sm={4} xs={5}>
            {fields ? (
              <PulldownMenu
                intVal={d.key}
                items={fields}
                onSelect={(val) => {
                  updateValue(idx, val, "key");
                }}
                windowWidth={topRect?.width}
              />
            ) : (
              <LinearProgress />
            )}
          </Grid>

          {/* Column 3; value UI */}
          <Grid item lg={6} md={5} sm={5} xs={3}>
            {getValueUI(idx, value, d.key ? valueStyle : inputStyle)}
          </Grid>

          {/* Column 4; the add / remove icon */}
          <Grid item md={1} sm={1} xs={1}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box sx={endColumnStyle}>
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    top: 4,
                    marginLeft: 1,
                  }}
                >
                  {searchMeta.length > 1 && (
                    <Tooltip
                      iconType={ICON_NONE}
                      title="Remove this line from builder"
                    >
                      <RemoveCircleIcon
                        sx={iconStyle}
                        onClick={() => {
                          add_remove(idx, ICON_REMOVE);
                        }}
                      />
                    </Tooltip>
                  )}
                  {(searchMeta.length === 1 ||
                    searchMeta.length - 1 === idx) && (
                    <Tooltip iconType={ICON_NONE} title="Add new builder line">
                      <AddCircleIcon
                        sx={
                          (typeof value === "object" &&
                            isDateRangeHasValidValue(d)) ||
                          (typeof value === "string" && value)
                            ? iconStyle
                            : { ...iconStyle, color: theme.grey500 }
                        }
                        onClick={
                          value
                            ? () => {
                                add_remove(idx, ICON_ADD);
                              }
                            : null
                        }
                      />
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </React.Fragment>
      );
    });
  };

  const queryValue = () => {
    const qlist = [];
    if (keyMap) {
      searchMeta.forEach((d, idx) => {
        const { value, key } = d;
        const operator = idx > 0 ? d.operator || ADV_SEARCH_OP[0] : "";
        const op = operator && ` ${operator} `;

        if (key) {
          const { name, type, range } = keyMap.desc2name[key];

          if (["date", "year"].includes(type)) {
            if (range && isDateRangeHasValidValue(d)) {
              const { from, to } = value;
              if (from || to) {
                if (type === "year") {
                  const fromDate = from ? `${from}-01-01` : jgiStartDate(); // 1997
                  const toDate = to ? `${to}-12-31` : currentYYYYMMDD();
                  const extValue = `\`[${fromDate} TO ${toDate}]\``;
                  qlist.push(`${op}${name}:${extValue}`);
                } else {
                  const fromDate = toYYYYMMDD(from);
                  const toDate = toYYYYMMDD(to, "to");
                  const extValue = `\`[${fromDate} TO ${toDate}]\``;
                  qlist.push(`${op}${name}:${extValue}`);
                }
              }
            } else if (!range && validateDateValue(key, value)) {
              if (type === "date") {
                qlist.push(`${op}${name}:\`${toYYYYMMDD(value)}\``);
              } else {
                const fromDate = `${value}-01-01`;
                const toDate = `${value}-12-31`;
                const extValue = `\`[${fromDate} TO ${toDate}]\``;
                qlist.push(`${op}${name}:${extValue}`);
              }
            }
          } else {
            const cleanedValue =
              value && typeof value === "string" ? value.trim() : null;
            if (cleanedValue) {
              const sval =
                cleanedValue.indexOf(" ") > -1
                  ? `(${cleanedValue})`
                  : cleanedValue;
              const extKey = name === ALL_FIELDS ? "" : `${name}:`;
              const extValue = name === ALL_FIELDS ? sval : `\`${sval}\``;
              qlist.push(`${op}${extKey}${extValue}`);
            }
          }
        }
      });
    }

    return qlist.join("");
  };

  const doAdvancedQuery = () => {
    const qstr = queryValue();
    const searchParams = new URLSearchParams(); // blank search params
    searchParams.set("q", qstr);
    searchParams.set("t", "advanced");
    const { pathname } = window.location;
    const advString = searchParams.toString(); // use plain text in URL
    const toPath = pathname !== "/" ? `?${advString}` : `/search?${advString}`;
    history.replace(toPath);
    honeycomb.sendUiInteractionSpan("adv-search-performed", {
      search_value: toPath,
      field_count: searchMeta?.length,
    });
  };

  const disableSearchButton = () => {
    let disable = false;
    const BreakException = {};
    try {
      searchMeta.forEach((d) => {
        const { key, value } = d;
        if (!key) {
          disable = true;
        } else {
          const { type, range } = keyMap.desc2name[key];
          if (["date", "year"].includes(type)) {
            if (
              (range && !isDateRangeHasValidValue(d)) ||
              (!range && !validateDateValue(key, value))
            ) {
              disable = true;
            }
          } else if (!value) {
            disable = true;
          }
        }

        if (disable) {
          throw BreakException;
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
    return disable;
  };

  const searchButtonConfig = {
    onClick: doAdvancedQuery,
    variant: "contained",
    color: "primary",
    size: "large",
    className: "button",
    style: { width: 100, height: 34 },
    id: "adv-search-btn-id",
    disabled: disableSearchButton(),
    children: "Search",
  };

  const searchButton = getNativeButton({ ...searchButtonConfig });

  return (
    <div>
      <Drawer anchor="top" open={open} onClose={onClose}>
        <Box sx={{ width: "auto", padding: "20px 40px" }} role="presentation">
          <Grid
            container
            sx={{
              width: "auto",
              margin: 0,
              padding: 0,
            }}
          >
            <Grid item xs={12} md={10} lg={8} xl={7}>
              <Grid
                container
                sx={{
                  width: "auto",
                  margin: 0,
                  padding: 0,
                }}
                spacing={1}
              >
                {/* row 1 */}
                <Grid item md={11} sm={11} xs={11}>
                  <Box sx={{ display: "flex" }}>
                    <TextField
                      id="advanced-query-value"
                      variant="outlined"
                      disabled
                      fullWidth
                      sx={inputSizeStyle}
                      value={queryValue() || ""}
                    />
                  </Box>
                </Grid>
                <Grid item md={1} sm={1} xs={1} />
                {/* end row 1 */}

                {/* middle rows */}
                {getSearchParamUI()}
                {/* end middle rows */}

                {/* last row */}
                <Grid item md={3} sm={3} xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    {searchButton}
                    <ButtonMui
                      sx={{
                        textTransform: "none",
                        textDecoration: "underline",
                        marginLeft: 2,
                        color: theme.grey700,
                        fontSize: 14,
                      }}
                      onClick={() => {
                        setSearchMeta(
                          JSON.parse(JSON.stringify([{ value: "" }]))
                        );
                        setErrorMsg(null);
                      }}
                    >
                      <Typography noWrap>Clear All</Typography>
                    </ButtonMui>
                  </Box>
                </Grid>
                <Grid item md={9} sm={9} xs={6}>
                  <Box sx={{ display: "flex" }}>
                    {isLoading ? (
                      <LinearProgress
                        style={{
                          position: "relative",
                          top: 12,
                          height: 10,
                          marginTop: 2,
                          marginRight: 40,
                        }}
                      />
                    ) : errorMsg ? (
                      <Box
                        style={{
                          backgroundColor: "#FFE4DE",
                          marginRight: 40,
                          padding: "4px 20px 8px",
                        }}
                      >
                        <ErrorIcon
                          style={{
                            position: "relative",
                            top: 6,
                            color: "#FF0000",
                            marginRight: 4,
                          }}
                        />
                        {errorMsg}
                      </Box>
                    ) : null}
                    <Box sx={{ marginLeft: "auto", minWidth: 340 }}>
                      {learnMoreUI}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </div>
  );
};

export default AdvancedSearch;

AdvancedSearch.defaultProps = {
  error: null,
  isLoading: false,
  open: false,
  onClose: () => {},
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  topRect: null,
  currentUser: {},
};

AdvancedSearch.propTypes = {
  error: PropTypes.shape(),
  isLoading: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  honeycomb: PropTypes.shape(),
  topRect: PropTypes.shape(),
  ldClient: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape(),
};
