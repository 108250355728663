/* istanbul ignore file */
import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useCookieWatcher } from "@fcannizzaro/react-use-cookie-watcher"; // useCookie() to get value

import Box from "@material-ui/core/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import WarningIcon from "@material-ui/icons/Warning";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Popover from "@mui/material/Popover";

import { makeStyles } from "@material-ui/core";
import Header from "../Header/Header";
import RoutingContainer from "../RoutingContainer/RoutingContainer";
import ErrorBoundary from "./ErrorBoundary";
import SysOutage, { MSG_SYSTEM_OUTAGE } from "../Alert/Sysinfo/SysOutage";
import GenericIssues from "../Alert/GenericIssues/GenericIssues";

import Footer from "../Footer/Footer";
import BackToTop from "../UI/BackToTop/BackToTop";
import {
  outageWarning,
  allGenericIssue,
  base as ldbase,
} from "../../utils/LDFFUtils";
import userTool from "../../utils/UserUtils";
import classes from "../../themes/theme.module.css";

import { useSearchParams } from "../../hooks/useSearchParams";

import PageAlert, {
  BROWSER_DOWNLOAD_SIZE_INFO,
  GLOBUS_DOWNLOAD_SIZE_INFO,
  TAPE_FILE_INFO,
  RESTORE_WILL_OVER_USER_QUOTA,
  RESTORE_OVER_USER_QUOTA,
  RESTORE_NEAR_USER_QUOTA,
  RESTORE_DISK_SPACE,
} from "../Alert/PageAlert/PageAlert";

// Ref : src/components/Card/Card.jsx - box shadow
const useStyles = makeStyles(() => ({
  root: {
    padding: "10px 40px 10px",
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
    boxShadow: "2px 6px 10px 2px #999",
  },
}));

const AppContainer = (props) => {
  const muiClasses = useStyles();
  const history = useHistory();
  const { honeycomb, ldClient, ldClientMaster, currentUser } = props;

  const [oversize, setOversize] = useState(null);
  const [tapedata, setTapedata] = useState(null);
  const [restore, setRestoreMessageId] = useState(null);
  const [topRect, setTopRect] = useState(null);
  const [fringeInfoList, setFringeInfoList] = useState(null);

  const { mdp } = useSearchParams();

  // cookie existence
  const pmosStaging = useCookieWatcher("pmos_env", {
    // check for changes every 500ms
    checkEvery: 500,
  });
  const admin_ff = ldbase(ldClientMaster, "jdp-admins");

  const topRef = useRef(null);
  const headerCartRef = useRef(null); // for shopping cart in header
  const headerRef = useRef(null);
  const footerRef = useRef(null);

  /* istanbul ignore next */
  useEffect(
    () =>
      history.listen((location) => {
        if (location.pathname === "/") {
          setOversize(null);
          setTapedata(null);
          setRestoreMessageId(null);
        }
        honeycomb.sendRoutingSpan(location.pathname);
      }),
    [history, honeycomb]
  );

  const handleResize = () => {
    // resetBodyHeight();
    if (topRef?.current) {
      const rect = topRef.current.getBoundingClientRect();
      setTopRect(rect);
    }
  };

  /* istanbul ignore next */
  const updateFingeInfo = () => {
    const infoList = [];
    if (currentUser?.mimic && !currentUser?.mimic.error) {
      const { mimic } = currentUser;

      infoList.push({
        label: "Mimicking user",
        value: `${mimic.first_name} ${mimic.last_name}`,
      });
      infoList.push({
        value: mimic.email_address.toLowerCase(),
      });
      infoList.push({
        value: `Contact ID: ${mimic.contact_id}`,
      });
      // show PMOS env info when mimicking, or usig staging
      if (infoList.length) {
        infoList.push({
          label: "PMOS Environment",
          value: pmosStaging && admin_ff ? "Staging" : "Production",
        });
      }
    }

    setFringeInfoList(infoList.length > 0 ? infoList : null);
  };

  const mydataPage = window.location.pathname.trim() === "/mydata";

  /* istanbul ignore next */
  useEffect(() => {
    updateFingeInfo();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    updateFingeInfo();
  }, [pmosStaging]);

  useEffect(() => {
    // initail size - windown listener will not catch this
    handleResize();
  }, [topRef.current]);

  const setAlertMessageID = useCallback((mid, show = true) => {
    // mid : message ID
    let updateFunc = null;

    switch (mid) {
      case GLOBUS_DOWNLOAD_SIZE_INFO:
      case BROWSER_DOWNLOAD_SIZE_INFO:
        updateFunc = setOversize;
        break;
      case TAPE_FILE_INFO:
        updateFunc = setTapedata;
        break;
      /* eslint-disable */
      /* istanbul ignore next */
      case RESTORE_WILL_OVER_USER_QUOTA:
      /* istanbul ignore next */
      case RESTORE_DISK_SPACE:
      /* istanbul ignore next */
      case RESTORE_OVER_USER_QUOTA:
      /* istanbul ignore next */
      case RESTORE_NEAR_USER_QUOTA:
        /* eslint-enable */
        updateFunc = setRestoreMessageId;
        break;
      default:
        /* istanbul ignore next */
        break;
    }

    updateFunc(show ? mid : null);
  }, []);

  let sysInfo = null;
  if (ldClient) {
    const warning = outageWarning(ldClient);
    if (warning && warning !== "OFF") {
      sysInfo = (
        <SysOutage
          type={MSG_SYSTEM_OUTAGE}
          text={warning}
          honeycomb={honeycomb}
        />
      );
    }

    /* istanbul ignore next */
    if (!sysInfo) {
      const gIssue = allGenericIssue(ldClient, userTool.userEmail(currentUser));

      if (gIssue?.length > 0) {
        sysInfo = gIssue.map((d, idx) => {
          const key = `${d.title.toLowerCase().replace(/ /g, "_")}_${idx}`;
          return (
            <GenericIssues
              key={key}
              title={d.title}
              subtitle={d.subtitle}
              message={d.message}
            />
          );
        });
      }
    }
  }

  const [headerCartThreshold, setHeaderCartThreshold] = useState(0); // to be shared with header and downloader for cart tool display
  useEffect(() => {
    if (headerCartRef?.current && headerCartThreshold === 0) {
      const cartRect = headerCartRef.current.getBoundingClientRect();
      setHeaderCartThreshold(cartRect.top + cartRect.height - 14);
    }
  }, [headerCartRef]);

  // the fringe UI
  const [marginLeft, setMarginLeft] = useState(100);
  const shortMessageStyle = {
    fontSize: 18,
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const fringeRef = useRef(null);
  const toggleFringePopup = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    }
    setAnchorEl(event.target);
  };
  const fringeInfoListTruncated = fringeInfoList && fringeInfoList.slice(0, 3); // leading 3 items

  useEffect(() => {
    if (fringeRef?.current && topRect) {
      const rect = fringeRef.current.getBoundingClientRect();
      const left = (topRect.width - rect.width) * 0.5;
      setMarginLeft(left);
    }
  }, [fringeRef?.current, topRect, fringeInfoList, mydataPage]);

  return (
    <>
      <Box
        data-testid="body-box"
        style={{ minHeight: "calc(100vh - 115px)" }}
        ref={topRef}
        className="AppRoot"
      >
        <Box ref={headerRef}>
          <Header
            {...props}
            headerCartRef={headerCartRef}
            headerCartThreshold={headerCartThreshold}
          />
          {sysInfo}
          <PageAlert mid={oversize} ldClient={ldClient} />
          <PageAlert mid={tapedata} ldClient={ldClient} />
          <PageAlert mid={restore} ldClient={ldClient} />
        </Box>

        <ErrorBoundary {...props}>
          <RoutingContainer
            topRect={topRect}
            topRef={topRef}
            headerRef={headerRef}
            footerRef={footerRef}
            {...props}
            setAppMessageID={setAlertMessageID}
            headerCartThreshold={headerCartThreshold}
          />
        </ErrorBoundary>
      </Box>

      <Footer footerRef={footerRef} {...props} />

      <BackToTop topRef={topRef} honeycomb={honeycomb} />

      {/*  display a sticky and centered banner to top of page  */}
      {fringeInfoList?.length > 0 && (
        <>
          <Box
            ref={fringeRef}
            style={{
              position: "fixed",
              top: 0,
              left: marginLeft,
              zIndex: 10000,
              width: "auto",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={toggleFringePopup}
          >
            <Box
              className={muiClasses.root}
              style={{
                backgroundColor: pmosStaging ? classes.orange50 : classes.red50,
                padding: "0px 40px",
              }}
            >
              <Grid container>
                <WarningIcon
                  style={{
                    color: pmosStaging ? classes.orange600 : classes.red600,
                    marginRight: 10,
                  }}
                />
                <Typography style={{ ...shortMessageStyle, marginRight: 8 }}>
                  Mimicking
                </Typography>{" "}
                <Typography
                  style={{
                    ...shortMessageStyle,
                    fontWeight: "bold",
                    marginRight: 8,
                  }}
                >
                  {fringeInfoList[0].value}
                </Typography>
                {/* Show PMOS Envirn only for My Projects (no mdp value in URL) */}
                {!mdp && (
                  <>
                    {" "}
                    <Typography
                      style={{ ...shortMessageStyle, marginRight: 8 }}
                    >
                      in
                    </Typography>
                    <Typography
                      style={{
                        ...shortMessageStyle,
                        color:
                          fringeInfoList[3].value === "Production"
                            ? classes.red600
                            : "#000",
                        fontWeight:
                          fringeInfoList[3].value === "Production"
                            ? "bold"
                            : "default",
                        marginRight: 4,
                      }}
                    >
                      {fringeInfoList[3].value}
                    </Typography>
                    <Typography
                      style={{
                        ...shortMessageStyle,
                        fontWeight: "bold",
                      }}
                    >
                      ...
                    </Typography>
                  </>
                )}
              </Grid>
            </Box>
          </Box>
          <Popover
            id="FringePopover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Grid container style={{ padding: 10 }}>
              {fringeInfoListTruncated.map((d, idx) => {
                const key = `${d.value}_${idx}`;

                return (
                  <React.Fragment key={key}>
                    <Typography
                      sx={{ ...shortMessageStyle, margin: "0px 4px" }}
                    >
                      {d.value}
                    </Typography>
                    {idx < fringeInfoListTruncated.length - 1 && (
                      <FiberManualRecordIcon
                        style={{
                          fontWeight: "bold",
                          position: "relative",
                          width: 12,
                          height: 12,
                          top: 8,
                          color: "#444",
                        }}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </Grid>
          </Popover>
        </>
      )}
      {/* Show PMOS Envirn only for My Projects (no mdp value in URL) */}
      {!fringeInfoList && mydataPage && !mdp && (
        <Box
          ref={fringeRef}
          style={{
            position: "fixed",
            top: 0,
            left: marginLeft,
            zIndex: 10000,
            width: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            className={muiClasses.root}
            style={{
              backgroundColor: pmosStaging ? classes.orange50 : classes.red50,
              padding: "0px 40px",
            }}
          >
            <Grid container>
              <WarningIcon
                style={{
                  color: pmosStaging ? classes.orange600 : classes.red600,
                  marginRight: 10,
                }}
              />
              <Typography
                style={{
                  fontSize: 18,
                  marginRight: 8,
                  fontWeight: "bold",
                }}
              >
                PMOS Environment:
              </Typography>{" "}
              <Typography style={{ fontSize: 18 }}>
                {pmosStaging && admin_ff ? "Staging" : "Production"}
              </Typography>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AppContainer;
AppContainer.defaultProps = {
  honeycomb: { sendRoutingSpan: () => {} },
  ldClient: null,
  currentUser: null,
};

AppContainer.propTypes = {
  honeycomb: PropTypes.shape({
    sendRoutingSpan: PropTypes.func,
  }),
  ldClient: PropTypes.shape({
    variation: PropTypes.func,
  }),
  currentUser: PropTypes.shape(),
  ldClientMaster: PropTypes.shape().isRequired,
};
