/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import { infoText } from "./styles";

import NoDraggableDialog, {
  DLG_ERROR,
} from "../../../UI/InfoBoard/Standalone/NoDraggableDialog";
import { DF_BTN_ERROR } from "../../../UI/Dialogs/DialogFooter";

const HttpErrorDlg = ({ cancelBtnFn, message, status }) => {
  if (message || status) {
    // eslint-disable-next-line no-console
    console.log(`[HttpErrorDlg] message=${message}; status=${status}`);
  }
  return (
    <NoDraggableDialog
      type={DLG_ERROR}
      title="Unexpected issue!"
      width={800}
      acceptBtnText="Close"
      acceptBtnFn={cancelBtnFn}
      cancelCrossFn={cancelBtnFn}
      btnColor={DF_BTN_ERROR}
    >
      <Typography paragraph sx={infoText}>
        {`We've encountered an unexpected issue and can not present your data at
        this time. The Data Portal Team has been notified.`}
      </Typography>
      <Typography paragraph>Please try again later.</Typography>
    </NoDraggableDialog>
  );
};

export default HttpErrorDlg;

HttpErrorDlg.defaultProps = {
  message: null,
  status: null,
};

HttpErrorDlg.propTypes = {
  message: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cancelBtnFn: PropTypes.func.isRequired,
};
