const currentTimeIsIn = (start, end) => {
  let yes = Boolean(start || end);
  const now = Date.now();
  if (start) {
    const st = new Date(start);
    yes = st.toString() !== "Invalid Date" && now > st;
  }

  if (yes && end) {
    const et = new Date(end);
    yes = et.toString() !== "Invalid Date" && et > now;
  }

  return yes;
};

const jgiStartDate = () => "1997-01-01";
const paddingTo2 = (val) => String(val).padStart(2, "0"); // padding with '0'

const currentYYYYMMDD = () => {
  const now = new Date();
  return `${now.getFullYear()}-${paddingTo2(now.getMonth() + 1)}-${paddingTo2(
    now.getDate()
  )}`;
};

const toYYYYMMDD = (value, type = "from") => {
  if (value) {
    const dObj = new Date(value.length === 4 ? `01/01/${value}` : value);
    const year = dObj.getFullYear();
    const month = paddingTo2(dObj.getMonth() + 1);
    const date = paddingTo2(dObj.getDate());
    return `${year}-${month}-${date}`;
  }

  return type === "from" ? jgiStartDate() : currentYYYYMMDD();
};

// if a given val is a valid 4 digit year: eg 1997, "1999"
const isJGIValidYear = (val) => {
  const reg = /^\d{4}$/; // only 4 digits

  if (!reg.test(val)) {
    return false;
  }
  const year = parseInt(val, 10);
  return year >= 1997 && year <= new Date().getFullYear();
};

// if a given val is in valid date format like MM/DD/YYYY
const isJGIValidDate = (val) => {
  const reg = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  const match = `${val}`.match(reg);

  if (!match) {
    return false;
  }

  // To avoid Date carryover behavior
  const month = parseInt(match[1], 10) - 1; // month from Date starts with 0
  const day = parseInt(match[2], 10);
  const year = parseInt(match[3], 10);
  const dt = new Date(val);
  const today = new Date();
  const valid =
    dt.getFullYear() === year &&
    dt.getMonth() === month &&
    dt.getDate() === day &&
    isJGIValidYear(year);

  if (valid && year === today.getFullYear()) {
    return dt <= today; //
  }
  return valid;
};

const validDateRange = (dfrom, dto) => {
  // need both values!
  let fs = `${dfrom}`;
  let ts = `${dto}`;
  if (fs.length !== ts.length) {
    return false;
  }
  if (fs.length === 4) {
    fs = `01/01/${fs}`;
    ts = `12/31/${ts}`; // end year
  }
  const fdt = new Date(fs);
  const tdt = new Date(ts);
  return tdt.getTime() > fdt.getTime();
};

export {
  currentTimeIsIn,
  toYYYYMMDD,
  currentYYYYMMDD,
  jgiStartDate,
  isJGIValidYear,
  isJGIValidDate,
  validDateRange,
};
