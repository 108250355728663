import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { InputAdornment, TextField } from "@material-ui/core/";
import { useQuery } from "react-query";
import Link from "@mui/material/Link";
import { Autocomplete } from "@material-ui/lab";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import { getAutocompleteSuggestions } from "../../utils/HttpClientProvider";
import useDebounce from "../../hooks/useDebounce";
import { useSearchParams } from "../../hooks/useSearchParams";
import ConfigContext from "../../context/ConfigContext";

import NewSearchWithSelectionDlg from "./Dialogs/NewSearchWithSelectionDlg";
import Tooltip, { ICON_NONE } from "../UI/Tooltip/Tooltip";

import theme from "../BentoBox/BentoBox.module.css";

const SearchAutocomplete = (props) => {
  const {
    honeycomb,
    autoStyling,
    textInputStyling,
    inputProps,
    adornmentProps,
    buttonClicked,
    setIsOpen,
    setQuery,
    updateUrlQuery,
    extraProps,
    addToCartHandler,
    withSelections,
    disabled,
  } = props;

  const { q, searchParams } = useSearchParams();
  const group = searchParams.get("group");

  // State of the current autocomplete (not necessarily 'submitted')
  const [newQuery, setNewQuery] = useState(q);
  const debouncedNewQuery = useDebounce(newQuery, 500);
  const [dialogConf, setDialogConf] = useState(null);

  useEffect(() => {
    if (disabled && group) {
      setNewQuery(group);
    } else if (q !== newQuery) {
      setNewQuery(q || "");
    }
  }, [q, group]);

  const preUpdateQuery = (value) => {
    if (value !== null && withSelections && addToCartHandler) {
      setDialogConf({ type: true, value });
    } else {
      updateUrlQuery(value);
    }
  };

  if (buttonClicked) {
    // console.log("[buttonClicked]");
    preUpdateQuery(newQuery || "");
  }

  // should match server-side min length
  const queryIsCompleteable = () => {
    if (newQuery && newQuery.length > 2) {
      return true;
    }
    return false;
  };

  const { suggestionUrl } = useContext(ConfigContext);

  const { data: autocompleteOptions } = useQuery(
    ["genome-search-autocomplete", debouncedNewQuery],
    () => getAutocompleteSuggestions(suggestionUrl, newQuery, honeycomb),
    {
      staleTime: Infinity, // finit value like 10 * 1000 causes repeated api calls (JDP-1786) even without user activity
      enabled: queryIsCompleteable(),
    }
  );

  /* eslint-disable-next-line no-unused-vars */
  const fetchAutocompleteOptions = (value, reason) => {
    // IMPORTANT we update the state of the query so that if user clicks Button
    // or presses Enter, the state of the query is always up-to-date.
    // Because we have a useQuery listening on changes to 'query', it will also trigger
    // a new fetch of options.
    // console.log("fetchAutocompleteOptions", { reason });
    setNewQuery(value);
    // this is the parent component query state tracker
    setQuery(value);
  };

  const onChangeHandler = (ev, value, reason) => {
    // possible reasons:
    // * select-option (user picked an autocomplete option)
    // * clear (user backspaced to empty or clicked X)
    // console.log("onChange", { value, newQuery, reason, eventType: ev.type });

    if (reason === "clear") {
      preUpdateQuery(null); // NOT! empty string
    } else if (reason === "select-option") {
      // if it was from Enter key press, ignore it,
      // because the onKeyDown handler will take care of it.
      // using setNewQuery inside the onKeyDown handler does not work because
      // both onKeyDown and onChange fire simultaneously and the newQuery
      // cannot update fast enough.
      if (ev.type === "keydown") {
        // console.log("ignoring keydown selection");
      }
      // if it was from clicking the option, use the option selected.
      if (ev.type === "click") {
        honeycomb.sendUiInteractionSpan(`autocomplete-user-select`, {
          use_selected: value,
          query_word: newQuery,
          reason,
          event_type: ev.type,
        });

        // delay the next call to give HC event a chance to complete. May want to add an immediate send function to HOC.
        setTimeout(() => {
          preUpdateQuery(value);
        }, 400);
      }
    }
  };

  // useful for debugging only.
  /* eslint-disable-next-line no-unused-vars */
  const onHightlightHandler = (value, reason) => {
    // console.log("onHighlight", { value, reason });
  };

  const dialog = withSelections && addToCartHandler && dialogConf && (
    <NewSearchWithSelectionDlg
      addToCartAndProceedBtnFn={() => {
        updateUrlQuery(dialogConf.value);
        setDialogConf(null);
        addToCartHandler();
      }}
      crossDismissBtnFn={() => {
        setDialogConf(null);
        setNewQuery(q);
      }}
      dropFilesANdProceedBtnFn={() => {
        updateUrlQuery(dialogConf.value);
        setDialogConf(null);
      }}
    />
  );

  const textStyle = disabled
    ? {
        style: {
          color: theme.grey600,
          marginTop: 0,
          fontWeight: "bold",
          fontSize: 20,
        },
      }
    : {};

  const adornmentDim = window.location.pathname === "/" ? 40 : 30; // home page 40, else 30
  const adornmentStyle = {
    width: adornmentDim,
    height: adornmentDim,
    color: theme.lake600,
  };

  return (
    <>
      <Autocomplete
        disabled={disabled}
        {...extraProps}
        freeSolo
        defaultValue={q}
        inputValue={newQuery || ""}
        value={newQuery || ""}
        {...autoStyling}
        onHighlightChange={(ev, value, reason) =>
          onHightlightHandler(value, reason)
        }
        onInputChange={(ev, value, reason) =>
          fetchAutocompleteOptions(value, reason)
        }
        onChange={(ev, value, reason) => onChangeHandler(ev, value, reason)}
        id="search-autocomplete"
        renderInput={(params) => (
          <TextField
            id="search-autocomplete-selections"
            label="Search"
            aria-label="Search text"
            onKeyDown={(ev) => {
              const enterKeyCaught = ev.keyCode === 13 || ev.code === "Enter";
              if (enterKeyCaught) {
                // ALWAYS use whatever is in the TextField input field
                preUpdateQuery(ev.target.value || "");
              }
            }}
            onFocus={
              setIsOpen
                ? () => {
                    setIsOpen(true);
                  }
                : {}
            }
            onBlur={
              setIsOpen
                ? () => {
                    setIsOpen(false);
                  }
                : {}
            }
            {...params}
            {...textInputStyling}
            InputProps={{
              ...inputProps,
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon
                    {...adornmentProps}
                    style={{
                      ...adornmentStyle,
                      color: disabled ? theme.grey600 : theme.lake600,
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <Tooltip iconType={ICON_NONE} title="Search tips">
                    <Link
                      href="https://sites.google.com/lbl.gov/data-portal-help/home/tips_tutorials/search-tips"
                      target="_blank"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <InfoOutlinedIcon
                        {...adornmentProps}
                        style={{ ...adornmentStyle }}
                      />
                    </Link>
                  </Tooltip>
                </InputAdornment>
              ),
              ...textStyle,
            }}
          />
        )}
        autoHighlight // user input always selected by default
        autoComplete // change input box as focus changes
        // debug // uncomment temporarily to inspect suggestion list DOM
        renderOption={(option, { inputValue }) => {
          const matches = match(option, inputValue);
          const parts = parse(option, matches);

          return (
            <div>
              {parts.map((part, idx) => (
                <span
                  key={`${idx.toString().slice()}-${option}-${part.text}`}
                  style={{ fontWeight: part.highlight ? 600 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
        // includeInputInList // this does not work as you might expect, so filterOptions instead.
        options={autocompleteOptions || []}
        // this must be present to *always* show options.
        filterOptions={(options) => {
          let filteredOptions = options;
          if (newQuery && newQuery.length) {
            filteredOptions = options.filter((v) => v !== newQuery);
            filteredOptions.unshift(newQuery); // what the user enters always first in list
          }
          return filteredOptions;
        }}
      />
      {dialog}
    </>
  );
};

export default SearchAutocomplete;

SearchAutocomplete.defaultProps = {
  updateUrlQuery: () => {},
  setQuery: () => {},
  extraProps: {},
  fullScreen: false,
  // honeycomb: {
  //   sendUiInteractionSpan: () => {},
  // },
  autoStyling: {},
  textInputStyling: {},
  inputProps: {},
  adornmentProps: {},
  buttonClicked: false,
  setIsOpen: () => {},
  addToCartHandler: null,
  withSelections: null,
  disabled: false,
};

SearchAutocomplete.propTypes = {
  updateUrlQuery: PropTypes.func,
  setQuery: PropTypes.func,
  extraProps: PropTypes.shape(),
  fullScreen: PropTypes.bool,
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }).isRequired,
  autoStyling: PropTypes.shape(),
  textInputStyling: PropTypes.shape(),
  inputProps: PropTypes.shape(),
  adornmentProps: PropTypes.shape(),
  buttonClicked: PropTypes.bool,
  setIsOpen: PropTypes.func,
  addToCartHandler: PropTypes.func,
  withSelections: PropTypes.bool,
  disabled: PropTypes.bool,
};
