import React, { useRef, useState } from "react";
import { PropTypes } from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Paper, Typography, IconButton, Link, Box } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

import Button from "@mui/material/Button";

import SegmentSelect from "./SegmentSelect/SegmentSelect";
import SearchBox from "./SearchBox/SearchBox";
import { useSearchParams } from "../../hooks/useSearchParams";

import classes from "./BentoBox.module.css";
import theme from "../../themes/theme.module.css";
import Tooltip, { ICON_WARN } from "../UI/Tooltip/Tooltip";

import TransientTooltip from "../UI/Tooltip/TransientTooltip/TransientTooltip";

import AdvancedSearch from "../AdvancedSearch/AdvancedSearch";
import BorderlessButton from "../Button/BorderlessButton";

import { useApi } from "../../hooks/useApi";
import { base as ldbase } from "../../utils/LDFFUtils";

const useStyles = makeStyles(() => ({
  iconButtonLabel: {
    display: "flex",
    flexDirection: "column",
  },
}));

const optionsList = [
  { value: "search", label: "Everything" },
  { value: "img", label: "IMG" },
  { value: "phytozome", label: "Phytozome" },
  { value: "mycocosm", label: "MycoCosm/PhycoCosm" },
];

const BentoBox = ({
  data,
  isLoading,
  variant,
  options,
  ldClient,
  advancedSearchOpen,
  setAdvancedSearch,
  ...props
}) => {
  const { honeycomb } = props;
  const [dividerVisibility, setDividerVisibility] = useState(true);
  const { q, is_advanced_search, searchParams } = useSearchParams();

  const history = useHistory();
  const { segment } = useParams();

  const copyToClipboardRef = useRef(null);

  const privateProjects = Boolean(searchParams.get("f") === "project_id");
  const group = searchParams.get("group");

  const disableUI = Boolean(privateProjects || group || is_advanced_search);

  const [apiCopied, setApiCopied] = useState(false);

  // State of the current url params, needed to reroute when one or the other changes
  const [query, setQuery] = useState(q || "");
  const [urlSegment, setUrlSegment] = useState(segment);

  const advanced_search_ff = ldbase(ldClient, "advanced-search");
  const { searchURL } = useApi();

  const updateUrlQuery = (newQuery) => {
    setQuery(newQuery);

    if (newQuery === null) return;

    if (newQuery === "") {
      const newLoc =
        urlSegment === "search" ? "/search" : `/refine-download/${urlSegment}`;
      window.location = newLoc;
      // history.push(newLoc);
      return;
    }

    const params = new URLSearchParams();
    params.set("q", newQuery.trim());
    const queryString = params.toString();
    const newLoc =
      urlSegment === "search"
        ? `/search?${queryString}`
        : `/refine-download/${urlSegment}?${queryString}`;

    // we want a clean state on every new query value, so issue a real redirect.
    window.location = newLoc;
    // history.push(newLoc);
  };

  const updateUrlSegment = (newSegment) => {
    setUrlSegment(newSegment);
    if (!newSegment) return;
    const params = new URLSearchParams(`q=${query}`);
    const queryString = params.toString();

    let newLoc =
      newSegment === "search" ? "/search" : `/refine-download/${newSegment}`;
    newLoc += query ? `?${queryString}` : "";

    // we want a clean state on every new query value, so issue a real redirect.
    window.location = newLoc;
    // history.push(newLoc);
  };

  const handleApiCopied = () => {
    setApiCopied(true);
    setTimeout(() => {
      setApiCopied(false);
    }, 1500);
  };

  const handleBackToRegularSearch = () => {
    history.replace("/search?q="); // set URL to basic search with q="" value
  };

  // the search tool: segment + search UI
  let segmentSearchUI = (
    <Paper
      elevation={0}
      variant={variant}
      className={disableUI ? classes.roundBoxDisabled : classes.roundBox}
    >
      {!is_advanced_search && (
        <SegmentSelect
          className={disableUI ? classes.selectBoxDisabled : classes.selectBox}
          options={options}
          setDividerVisibility={setDividerVisibility}
          updateUrlSegment={updateUrlSegment}
          {...props}
          disabled={disableUI}
        />
      )}
      {is_advanced_search ? null : dividerVisibility ? (
        <Typography className={classes.divider}>|</Typography>
      ) : (
        <Typography className={classes.dividerHidden}>|</Typography>
      )}

      <SearchBox
        className={disableUI ? classes.searchBoxDisabled : classes.searchBox}
        setDividerVisibility={setDividerVisibility}
        updateUrlQuery={updateUrlQuery}
        setQuery={setQuery}
        {...props}
        disabled={disableUI}
      />
    </Paper>
  );

  // tooltip text can be different for different scenarios
  const tooltipText = privateProjects
    ? "Please go to the homepage to start a search for public data."
    : group
    ? "Please go to the homepage to start a new search."
    : null;
  if (tooltipText) {
    segmentSearchUI = (
      <Tooltip iconType={ICON_WARN} title={tooltipText} followCursor>
        {segmentSearchUI}
      </Tooltip>
    );
  }

  // The Copy API UI
  const btnClass = useStyles();
  const copyAPITool = (
    <Box
      style={{
        width: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        visibility: privateProjects ? "hidden" : "default",
      }}
    >
      <CopyToClipboard
        text={searchURL}
        ref={copyToClipboardRef}
        onCopy={() => {
          handleApiCopied();
          honeycomb.sendUiInteractionSpan("copy-api-performed");
        }}
      >
        <TransientTooltip
          noticeLabel={apiCopied ? "Value copied to clipboard" : null}
          labelConf={
            apiCopied
              ? null
              : [
                  {
                    type: "title",
                    value: "Copy API Search Query to Clipboard",
                  },
                  {
                    type: "node",
                    value: (
                      <Typography key="api-ref">
                        <Link
                          href="https://sites.google.com/lbl.gov/data-portal-help/home/tips_tutorials/api-tutorial?authuser=0#h.kl5mmcyatsql"
                          target="_blank"
                          style={{ textDecoration: "underline" }}
                          onClick={() => {
                            honeycomb.sendUiInteractionSpan(
                              "doc-link-for-copy-api-clicked"
                            );
                          }}
                        >
                          Learn more
                        </Link>{" "}
                        about the API.
                      </Typography>
                    ),
                  },
                ]
          }
          child={
            <IconButton
              id="copy-api-ibutton"
              classes={{ label: btnClass.iconButtonLabel }}
              sx={{
                textTransform: "none",
                fontSize: 14,
                fontFamily: "Public Sans, sans-serif",
                fontStyle: "normal",
                fontWeight: 600,
                letterSpacing: 0.16,
                textAlign: "left",
              }}
              type="button"
              variant="contained"
              size="small"
              color="secondary"
              onClick={(e) => {
                copyToClipboardRef.current.onClick(e);
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ContentCopyIcon style={{ color: classes.lake400 }} />
                <Typography style={{ color: "#000" }}>API</Typography>
              </Box>
            </IconButton>
          }
          open={apiCopied}
        />
      </CopyToClipboard>
    </Box>
  );

  return (
    <>
      <Box id="bento-box-top-div" className={classes.bentoBoxGrid}>
        {is_advanced_search && (
          <Button
            sx={{
              height: 30,
              width: 200,
              marginTop: "22px",
              marginLeft: 2,
              textTransform: "none",
              color: classes.lake500,
              border: `solid 1px ${classes.lake600}`,
              borderRadius: 8,
              "&:hover": {
                color: classes.lake600,
              },
            }}
            startIcon={
              <KeyboardReturnIcon
                style={{
                  fontSize: 24,
                  color: theme.grey500,
                  position: "relative",
                  top: 2,
                  right: -6,
                }}
              />
            }
            onClick={handleBackToRegularSearch}
          >
            Return to Basic Search
          </Button>
        )}
        {segmentSearchUI}
        {advanced_search_ff && (
          <Box style={{ height: 40, marginTop: 14 }}>
            <BorderlessButton
              label="Advanced Search"
              onClick={() => {
                setAdvancedSearch(true);
              }}
            />
          </Box>
        )}
        {copyAPITool}
      </Box>
      {(is_advanced_search || advancedSearchOpen) && (
        <AdvancedSearch
          {...props}
          error={data?.error}
          isLoading={isLoading}
          open={advancedSearchOpen}
          onClose={() => {
            setAdvancedSearch(false);
          }}
          ldClient={ldClient}
        />
      )}
    </>
  );
};

export default BentoBox;

BentoBox.defaultProps = {
  data: null,
  isLoading: false,
  variant: "elevation",
  options: optionsList,
  ldClient: null,
  advancedSearchOpen: false,
  setAdvancedSearch: () => {},
};

BentoBox.propTypes = {
  data: PropTypes.shape(),
  isLoading: PropTypes.bool,
  variant: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }).isRequired,
  ldClient: PropTypes.shape(),
  advancedSearchOpen: PropTypes.bool,
  setAdvancedSearch: PropTypes.func,
};
